<template>
  <div>
    <h1 v-show="edit">Contrato Ad Honorem</h1>
    <br />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha junta de consejo de gobierno"
      v-model="adHonoremContract.governingCouncilDate"
      v-mask="'####-##-##'"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Vigencia del nombramiento"
      v-model="adHonoremContract.validityDate"
      v-mask="'####-##-##'"
    />
    <hr />
    <br />
    <h2>Titulaciones</h2>
    <br />
    <v-text-field outlined label="Nombre" v-model="addTitulation.name" />
    <v-select
      outlined
      :items="titulationTypePicker"
      label="Tipo de titulacion"
      v-model="addTitulation.type"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha"
      v-model="addTitulation.date"
      v-mask="'####-##-##'"
    />
    <v-file-input
      v-model="addTitulation.documents"
      outlined
      chips
      multiple
      label="Documentos a adjuntar"
      show-size
    />
    <v-row>
      <v-spacer />
      <v-btn text @click="addTitulationToContract()">Agregar titulación</v-btn>
    </v-row>
    <v-data-table :items="adHonoremContract.titulations" :headers="titulationHeaders">
      <template v-slot:item.date="{ item }">
        {{ new Date(item.date).toLocaleDateString() }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ titulationTypePicker[item.type].text }}
      </template>
      <template v-slot:item.controls="{ item }">
        <v-btn text color="red" >Eliminar</v-btn>
      </template>
    </v-data-table>
    <br />
    <hr />
    <br />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha impresion acuerdo"
      v-model="adHonoremContract.agreementPrintDate"
      v-mask="'####-##-##'"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha aceptación acuerdo"
      v-model="adHonoremContract.appointmentAcceptanceDate"
      v-mask="'####-##-##'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['edit'],
  data: () => ({
    governingCouncilDate: false,
    agreementPrintDate: false,
    appointmentAcceptanceDate: false,
    validityDate: false,
    titulationDateModal: false,
    titulationHeaders: [
      { text: 'Nombre titulacion', value: 'name' },
      { text: 'Fecha', value: 'date' },
      { text: 'Tipo de titulacion', value: 'type' },
      { text: '', value: 'controls', sortable: false },
    ],
    titulationTypePicker: [
      { text: 'Bachiller o equivalente', value: 0 },
      { text: 'Ciclo Formativo de Grado Medio o equivalente', value: 1 },
      { text: 'Ciclo Formativo de Grado Superior o equivalente', value: 2 },
      { text: 'Grado / Licenciatura / Diplomatura', value: 3 },
      { text: 'Máster', value: 4 },
      { text: 'Doctorado', value: 5 },
      { text: 'Otros', value: 6 }
    ],
    addTitulation: {
      type: null,
      name: null,
      date: null,
      documents: [],
    }
  }),
  methods: {
    resetAddTitulation() {
      return {
        type: null,
        name: null,
        date: null,
        documents: [],
      }
    },
    addTitulationToContract() {
      if (JSON.stringify(this.addTitulation) !== JSON.stringify(this.resetAddTitulation())) {
        this.contractTitulations.push(this.addTitulation)
        this.addTitulation = this.resetAddTitulation()
      }
    },
  },
  computed: {
    ...mapState(['adHonoremContract']),
  }
};
</script>

<style>

</style>
