const initialState = () => ({
  level: null,
  titulation: null,
  startDate: null,
  endDate: null,
  internshipAmount: null,
  feeExemption: null,
  residencyInternship: false
})

const collaborationInternshipContract = {
  namespaced: true,
  state: initialState,
  mutations: {
    setLevel: (state, payload) => state.level = payload,
    setTitulationType: (state, payload) => state.titulationType = payload,
    setStartDate: (state, payload) => state.startDate = payload,
    setEndDate: (state, payload) => state.endDate = payload,
    setInternshipAmount: (state, payload) => state.internshipAmount = payload,
    setFeeExemption: (state, payload) => state.feeExemption = payload,
    setResidencyInternship: (state, payload) => state.residencyInternship = payload,
    reset: (state) => Object.assign(state, initialState()),
    setAll: (state, payload) => Object.assign(state, payload)
  }
}

export default collaborationInternshipContract
