const initialState = () => ({
  entity: undefined,
  modality: undefined,
  professionalGroup: undefined,
  category: undefined,
  location: undefined,
  schedule: undefined,
  hours: undefined,
  startDate: undefined,
  endDate: undefined,
  trialPeriod: undefined,
  collectiveAgreement: undefined,
  dedication: undefined,
  anualRetribution: undefined,
  undefinedEndDate: undefined
})

const laboralContract = {
  namespaced: true,
  state: initialState,
  mutations: {
    setEntity: (state, payload) => state.entity = payload,
    setModality: (state, payload) => state.modality = payload,
    setProfessionalGroup: (state, payload) => state.professionalGroup = payload,
    setCategory: (state, payload) => state.category = payload,
    setLocation: (state, payload) => state.location = payload,
    setHours: (state, payload) => state.hours = payload,
    setStartDate: (state, payload) => state.startDate = payload,
    setEndDate: (state, payload) => state.endDate = payload,
    setTrialPeriod: (state, payload) => state.trialPeriod = payload,
    setCollectiveAgreement: (state, payload) => state.collectiveAgreement = payload,
    setDedication: (state, payload) => state.dedication = payload,
    setAnualRetribution: (state, payload) => state.anualRetribution = payload,
    reset: (state) => Object.assign(state, initialState()),
    setAll: (state, payload) => Object.assign(state, payload)
  }
}

export default laboralContract
