<template>
  <v-container>
    <v-row class="row">
      <h2>Ascendientes</h2>
      <v-spacer />
      <v-dialog
        v-model="addAscendencyDialog"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">Agregar ascendiente</v-btn>
        </template>
        <v-card>
          <v-card-title>Agregar ascendiente</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field outlined label="Nombre" v-model="addAscendencyInfo.name" />
              <v-text-field
                outlined
                label="Fecha de nacimiento"
                placeholder="YYYY-MM-DD"
                v-mask="'####-##-##'"
                v-model="addAscendencyInfo.dateOfBirth"
              />
              <v-row>
                <v-select
                  outlined
                  label="Grado de discapacidad"
                  :items="discapacityLevel"
                  v-model="addAscendencyInfo.discapacity"
                />
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <v-checkbox
                  label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida"
                  v-model="addAscendencyInfo.discapacityNeedsHelp"
                />
              </v-row>
              <v-switch v-model="addAscendencyInfo.uniqueConvivence" label="Unica convivencia" />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="addAscendent">Agregar</v-btn>
            <v-btn text @click="addAscendencyDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div v-if="ascendents">
      <v-data-table :items="ascendents" :headers="ascendencyHeaders">
        <template v-slot:item.controls="{ item }">
          <v-btn text @click="openEditAscendencyDialog(item)">Editar</v-btn>
          <v-btn text color="red" @click="removeAscendent(item.id)">Eliminar</v-btn>
        </template>
        <template v-slot:item.uniqueConvivence="{ item }">
          {{ item.uniqueConvivence ? 'Si' : 'No'  }}
        </template>
        <template v-slot:item.discapacityNeedsHelp="{ item }">
          {{ item.discapacityNeedsHelp ? 'Si' : 'No' }}
        </template>
      </v-data-table>
      <br />
    </div>
    <div v-else>
      <h3>Esta persona no tiene familiares ascendientes</h3>
    </div>
    <br />
    <v-row class="row">
      <h2>Descendientes</h2>
      <v-spacer />
      <v-dialog
        v-model="addDescendencyDialog"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">Agregar descendiente</v-btn>
        </template>
        <v-card>
          <v-card-title>Agregar descendiente</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field outlined label="Nombre" v-model="addDescendencyInfo.name" />
              <v-text-field
                outlined
                label="Fecha de nacimiento"
                placeholder="YYYY-MM-DD"
                v-mask="'####-##-##'"
                v-model="addDescendencyInfo.dateOfBirth"
              />
              <v-row>
                <v-select
                  outlined
                  label="Grado de discapacidad"
                  :items="discapacityLevel"
                  v-model="addDescendencyInfo.discapacity"
                />
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <v-checkbox
                  label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida"
                  v-model="addDescendencyInfo.discapacityNeedsHelp"
                />
              </v-row>
              <v-switch v-model="addDescendencyInfo.uniqueConvivence" label="Unica convivencia" />
              <v-switch v-model="addDescendencyInfo.adopted" label="Adoptado" />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="addDescendent">Agregar</v-btn>
            <v-btn text @click="addDescendencyDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div v-if="descendents.length > 0">
      <v-data-table :items="descendents" :headers="descendencyHeaders">
        <template v-slot:item.controls="{ item }">
          <v-btn text @click="openEditDescendencyDialog(item)">Editar</v-btn>
          <v-btn text color="red" @click="removeDescendent(item.id)">Eliminar</v-btn>
        </template>
        <template v-slot:item.uniqueConvivence="{ item }">
          {{ item.uniqueConvivence ? 'Si' : 'No'  }}
        </template>
        <template v-slot:item.adopted="{ item }">
          {{ item.adopted ? 'Si' : 'No'  }}
        </template>
        <template v-slot:item.discapacityNeedsHelp="{ item }">
          {{ item.discapacityNeedsHelp ? 'Si' : 'No' }}
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h3>Esta persona no tiene familiares descendientes</h3>
    </div>
    <v-dialog
      v-model="editAscendencyDialog"
      persistent
    >
      <v-card>
        <v-card-title>Editar ascendiente</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field outlined label="Nombre" v-model="editAscendencyInfo.name" />
              <v-text-field
                outlined
                label="Fecha de nacimiento"
                placeholder="YYYY-MM-DD"
                v-mask="'####-###-##'"
                v-model="editAscendencyInfo.dateOfBirth"
              />
              <v-row>
                <v-select
                  outlined
                  label="Grado de discapacidad"
                  :items="discapacityLevel"
                  v-model="editAscendencyInfo.discapacity"
                />
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <v-checkbox
                  label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida"
                  v-model="editAscendencyInfo.discapacityNeedsHelp"
                />
              </v-row>
              <v-switch v-model="editAscendencyInfo.uniqueConvivence" label="Unica convivencia" />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="editAscendency">Editar</v-btn>
            <v-btn text @click="editAscendencyDialog = false">Cancelar</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDescendencyDialog"
      persistent
    >
      <v-card>
        <v-card-title>Editar descendiente</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field
                outlined
                label="Nombre"
                v-model="editDescendencyInfo.name"
              />
              <v-text-field
                outlined
                label="Fecha de nacimiento"
                placeholder="YYYY-MM-DD"
                v-mask="'####-##-##'"
                v-model="editDescendencyInfo.dateOfBirth"
              />
              <v-row>
                <v-select
                  outlined
                  label="Grado de discapacidad"
                  :items="discapacityLevel"
                  v-model="editDescendencyInfo.discapacity"
                />
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <v-checkbox
                  label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida"
                  v-model="editDescendencyInfo.discapacityNeedsHelp"
                />
              </v-row>
              <v-switch v-model="editDescendencyInfo.uniqueConvivence" label="Unica convivencia" />
              <v-switch v-model="editDescendencyInfo.adopted" label="Adoptado" />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="editDescendency">Editar</v-btn>
            <v-btn text @click="editDescendencyDialog = false">Cancelar</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ['id'],
  async beforeMount() {
    const { data: { data: ascendents } } = await this.$http.get(`/persons/${this.id}/ascendents`);
    const { data: { data: descendents } } = await this.$http.get(`/persons/${this.id}/descendents`);

    this.ascendents = ascendents || [];
    this.descendents = descendents || [];
  },
  data() {
    return {
      addAscendencyDialog: false,
      editAscendencyDialog: false,
      editDescendencyDialog: false,
      discapacityLevel: [
        { text: 'Ninguno', value: 0 },
        { text: 'Igual o superior al 33% e inferior al 65%', value: 33 },
        { text: 'Igual o superior al 65%', value: 65 }
      ],
      editDescendencyInfo: {
        id: null,
        name: null,
        dateOfBirth: null,
        discapacity: null,
        discapacityNeedsHelp: null,
        uniqueConvivence: null,
        adopted: null,
      },
      editAscendencyInfo: {
        id: null,
        name: null,
        dateOfBirth: null,
        discapacity: null,
        discapacityNeedsHelp: null,
        uniqueConvivence: null,
      },
      ascendencyDate: false,
      descendencyDate: false,
      addDescendencyDialog: false,
      ascendencyHeaders: [
        { text: 'Nombre', value: 'name' },
        { text: 'Fecha de nacimiento', value: 'dateOfBirth' },
        { text: '% discapacidad', value: 'discapacity' },
        { text: 'Discapacidad necesita ayuda', value: 'discapacityNeedsHelp' },
        { text: 'Unica convivencia', value: 'uniqueConvivence' },
        { text: '', value: 'controls', sortable: false },
      ],
      descendencyHeaders: [
        { text: 'Nombre', value: 'name' },
        { text: 'Fecha de nacimiento', value: 'dateOfBirth' },
        { text: '% discapacidad', value: 'discapacity' },
        { text: 'Discapacidad necesita ayuda', value: 'discapacityNeedsHelp' },
        { text: 'Unica convivencia', value: 'uniqueConvivence' },
        { text: 'Adoptado', value: 'adopted' },
        { text: '', value: 'controls', sortable: false },
      ],
      addAscendencyInfo: {
        name: null,
        dateOfBirth: null,
        discapacity: 0,
        discapacityNeedsHelp: false,
        uniqueConvivence: false,
      },
      addDescendencyInfo: {
        name: null,
        dateOfBirth: null,
        discapacity: 0,
        uniqueConvivence: false,
        discapacityNeedsHelp: false,
        adopted: false,
      },
      ascendents: [],
      descendents: [],
    };
  },
  methods: {
    async addAscendent() {
      try {
        const { data } = await this.$http.post(`/persons/${this.id}/ascendents`, {
          ...this.addAscendencyInfo,
        });

        this.ascendents.push(data);

        this.addAscendencyDialog = false;
        this.addAscendencyInfo.name = null;
        this.addAscendencyInfo.dateOfBirth = null;
        this.addAscendencyInfo.discapacity = 0;
        this.addAscendencyInfo.discapacityNeedsHelp = false;
        this.addAscendencyInfo.uniqueConvivence = false;
      } catch {
        this.addAscendencyDialog = false;
      }
    },
    async addDescendent() {
      try {
        const { data } = await this.$http.post(`/persons/${this.id}/descendents`, {
          ...this.addDescendencyInfo,
        });

        this.descendents.push(data);

        this.addDescendencyDialog = false;
        this.addDescendencyInfo.name = null;
        this.addDescendencyInfo.dateOfBirth = null;
        this.addDescendencyInfo.discapacity = 0;
        this.addDescendencyInfo.discapacityNeedsHelp = false;
        this.addDescendencyInfo.uniqueConvivence = false;
        this.addDescendencyInfo.adopted = false;
      } catch {
        this.addDescendencyDialog = false;
      }
    },
    async removeAscendent(id) {
      try {
        await this.$http.delete(`/persons/${this.id}/ascendents/${id}`);

        this.ascendents = this.ascendents.filter((ascendent) => ascendent.id !== id);
      } catch {}
    },
    async removeDescendent(id) {
      try {
        await this.$http.delete(`/persons/${this.id}/descendents/${id}`);

        this.descendents = this.descendents.filter((descendent) => descendent.id !== id);
      } catch {}
    },
    async openEditDescendencyDialog(item) {
      this.editDescendencyInfo = { ...item }

      this.editDescendencyDialog = true;
    },
    async openEditAscendencyDialog(item) {
      this.editAscendencyInfo = { ...item }

      this.editAscendencyDialog = true;
    },
    async editAscendency() {
      try {
        const { id, ...rest } = this.editAscendencyInfo;
        const { data } = await this.$http.patch(
          `/persons/${this.id}/ascendents/${this.editAscendencyInfo.id}`,
          { ...rest },
        );

        this.editAscendencyDialog = false;
        this.ascendents.splice(this.ascendents.findIndex((x) => x.id === id), 1, data);
      } catch {}
    },
    async editDescendency() {
      try {
        const { id, ...rest } = this.editDescendencyInfo;
        const { data } = await this.$http.patch(
          `/persons/${this.id}/descendents/${this.editDescendencyInfo.id}`,
          { ...rest },
        );

        this.editDescendencyDialog = false;
        this.descendents.splice(this.descendents.findIndex((x) => x.id === id), 1, data);
      } catch {}
    },
  },
};
</script>

<style>
.row {
  margin: 0 !important;
}
</style>
