<template>
  <v-container>
    <h1>Configuración</h1>
    <br />
    <v-divider />
    <br />
    <h2>Selectores</h2>
    <br />
    <v-data-table :headers="tableHeaders" :items="selectors" show-expand>
      <template v-slot:item.controls="{ item }">
        <v-btn text @click="openEditSelectorDialog(item)">Editar</v-btn>
        <v-btn text @click="deleteSelector(item.id)">Eliminar</v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <span class="mr-2">Valores</span>
          <v-chip v-for="value in item.values" :key="value.id" class="mr-2">{{ value.name }}</v-chip>
        </td>
      </template>
    </v-data-table>
    <v-dialog
      v-model="createSelectorDialog"
      scrollable
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">Crear selector</v-btn>
      </template>
      <v-card>
        <v-toolbar outlined flat>
          <v-toolbar-title>Agregar selector</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <br />
            <v-text-field outlined label="Nombre" v-model="createSelectorName" />
            <v-switch v-model="createSelectorActive" label="Activo" />
            <v-combobox
              v-model="createSelectorValues"
              chips
              clearable
              multiple
              append-icon
              outlined
              label="Valores"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="createSelector">Crear</v-btn>
          <v-btn text @click="createSelectorDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editSelectorDialog" hide-overlay persistent>
      <v-card>
        <v-toolbar outlined flat>
          <v-toolbar-title>Editar selector</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <br />
            <v-text-field outlined label="Nombre" v-model="createSelectorName" />
            <v-switch v-model="createSelectorActive" label="Activo" />
            <v-combobox
              v-model="createSelectorValues"
              chips
              clearable
              multiple
              append-icon
              outlined
              label="Valores"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-combobox>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="editSelector">Editar</v-btn>
          <v-btn text @click="closeEditSelectorDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  async beforeMount() {
    await this.fetchSelectors();
  },
  data: () => ({
    createSelectorDialog: false,
    createSelectorName: null,
    createSelectorActive: false,
    createSelectorValues: [],
    editSelectorDialog: false,
    selectors: [],
    tableHeaders: [
      { text: 'Nombre', value: 'name' },
      { text: 'Activo', value: 'active' },
      { text: '', value: 'controls', sortable: false },
    ],
  }),
  methods: {
    async createSelector() {
      try {
        const { data: { id } } = await this.$http.post('/selectors', {
          name: this.createSelectorName,
          active: this.createSelectorActive,
        });

        if (id !== undefined) {
          const promiseArray = [];

          for (let x = 0; x < this.createSelectorValues.length; x += 1) {
            promiseArray.push(this.$http.post(`selectors/${id}/values`, {
              name: this.createSelectorValues[x],
            }));
          }

          Promise.all(promiseArray);
        }

        await this.fetchSelectors();

        this.createSelectorDialog = false;
        this.createSelectorName = null;
        this.createSelectorActive = false;
        this.createSelectorValues = [];
      } catch {
        this.createSelectorDialog = false;
      }
    },
    async deleteSelector(id) {
      try {
        await this.$http.delete(`/selectors/${id}`)

        await this.fetchSelectors();
      } catch {}
    },
    async editSelector(id) {},
    async openEditSelectorDialog(item) {
      this.createSelectorName = item.name;
      this.createSelectorActive = item.active;
      this.createSelectorValues = item.values;
      this.editSelectorDialog = true;
    },
    async closeEditSelectorDialog() {
      this.editSelectorDialog = false;
      this.createSelectorName = null;
      this.createSelectorActive = false;
      this.createSelectorValues = [];
    },
    async fetchSelectors() {
      const { data } = await this.$http.get('/selectors');

      this.selectors = data.map((x) => ({
        id: x.id,
        name: x.name,
        active: x.active === 'true' ? 'Activo' : 'Inactivo',
        values: x.values,
      }));
    },
  },
};
</script>

<style>

</style>
