<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      :loading="tableLoading"
      :server-items-length="tableTotalAmount"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 20]
      }"
    >
      <template v-slot:item.controls="{ item }">
        <v-btn text @click="getPerson(item.id)">Ver</v-btn>
        <v-btn text color="red" @click="deletePerson(item.id)">Eliminar</v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="createPersonDialog"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Agregar persona
        </v-btn>
      </template>
      <v-card>
        <v-toolbar outlined flat>
          <v-toolbar-title>Agregar persona</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
          <br />
          <h1>Datos personales</h1>
          <br />
          <v-text-field outlined label="Nombre" v-model="personInformation.name" />
          <v-text-field outlined label="Apellidos" v-model="personInformation.lastName" />
          <v-text-field outlined label="NIE/NIF" v-model="personInformation.idDocument" />
          <v-text-field
            outlined
            label="N. seguridad social"
            v-model="personInformation.socialSecurity"
          />
          <v-text-field
            outlined
            placeholder="YYYY-MM-DD"
            label="Fecha de nacimiento"
            v-mask="'####-##-##'"
            v-model="personInformation.dateOfBirth"
          />
          <v-text-field outlined label="Nacionalidad" v-model="personInformation.nationality" />
          <v-select outlined :items="sexSelect" label="Genero" v-model="personInformation.sex" />
          <v-row>
            <v-select
              outlined
              :items="discapacityLevel"
              label="Grado de discapacidad"
              v-model="personInformation.discapacityLevel"
            />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <v-checkbox
              label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida"
              v-model="personInformation.discapacityNeedsHelp"
            />
          </v-row>
          <v-select
            outlined
            :items="civilStateSelect"
            label="Estado civil"
            v-model="personInformation.civilState"
          />
          <v-text-field
            v-show="personInformation.civilState === 1"
            outlined
            label="NIE/NIF del cónyuge"
            v-model="personInformation.partnerIdDocument"
          />
          <v-text-field outlined label="Dirección" v-model="personInformation.line" />
          <v-text-field outlined label="Pais" v-model="personInformation.country" />
          <v-text-field outlined label="Provincia" v-model="personInformation.division" />
          <v-text-field outlined label="Poblacion" v-model="personInformation.city" />
          <v-text-field outlined label="Codigo postal" v-model="personInformation.postalCode" />
          <v-text-field outlined label="Email personal" v-model="personInformation.personalEmail" />
          <v-text-field
            outlined
            label="Email institucional"
            v-model="personInformation.institutionalEmail"
          />
          <v-text-field
            outlined
            label="Telefono movil"
            v-model="personInformation.mobilePhoneNumber"
          />
          <v-text-field
            outlined
            label="Telefono fijo"
            v-model="personInformation.phoneNumber"
          />
          <v-file-input
            outlined
            accept="image/png, image/jpeg"
            placeholder="Avatar"
            prepend-icon="mdi-camera"
            v-model="profilePicture"
            label="Avatar"
          />
          <v-divider />
          <br />
          <h1>Datos academicos</h1>
          <br />
          <v-select
            outlined
            :items="maximumStudiesSelect"
            label="Maxima categoria de estudios finalizados"
            v-model="personInformation.academics.maximumStudies"
          />
          <v-select
            :items="accreditationAgencies"
            outlined
            label="Agencia de Evaluación para acreditación"
            v-model="personInformation.academics.accreditationAgency"
          />
          <v-select
            outlined
            :items="titulationSelect"
            label="Titulacion efectos de la ANECA"
            v-model="personInformation.academics.titulation"
          />
          <v-divider />
          <br />
          <h1>Datos profesionales</h1>
          <br />
          <v-select
            outlined
            :items="professionalTypes"
            v-model="personInformation.professional.type"
            label="Tipo"
          />
          <v-select
            outlined
            :items="departments"
            v-model="personInformation.professional.department"
            label="Departamento"
          />
          <v-select
            outlined
            :items="faculties"
            v-model="personInformation.professional.faculty"
            label="Facultad o escuela"
          />
          <v-select
            outlined
            :items="knowledgeFields"
            v-model="personInformation.professional.field"
            label="Campo de conocimiento"
          />
          <v-text-field
            outlined
            v-model="personInformation.professional.position"
            label="Cargo"
          />
          <v-divider />
          <br />
          <v-file-input v-model="documents" outlined chips multiple label="Documentos a adjuntar" show-size />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="addPerson">Agregar</v-btn>
          <v-btn text @click="createPersonDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn @click="exportToXlsx">Exportar a Excel</v-btn>
    <v-snackbar color="error" multi-line v-model="errorDisplay">
      {{ errorText }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  async beforeMount() {
    // await this.fetchPersons();
  },
  data: () => ({
    tableOptions: {},
    tableLoading: true,
    tableTotalAmount: null,
    createPersonDialog: false,
    documents: [],
    profilePicture: null,
    errorDisplay: false,
    errorText: null,
    discapacityLevel: [
      { text: 'Ninguno', value: 0 },
      { text: 'Igual o superior al 33% e inferior al 65%', value: 33 },
      { text: 'Igual o superior al 65%', value: 65 }
    ],
    professionalTypes: [
      { text: 'Personal Docente e Investigador', value: 0 },
      { text: 'Personal de Administración y Servicios', value: 1 },
      { text: 'Otros', value: 2 }
    ],
    knowledgeFields: [
      { text: 'Ciencias exactas y naturales', value: 0 },
      { text: 'Ingeniería y tecnología', value: 1 },
      { text: 'Ciencias médicas', value: 2 },
      { text: 'Ciencias de la agricultura y veterinaria', value: 3 },
      { text: 'Ciencias sociales', value: 4 },
      { text: 'Humanidades y las artes', value: 5 }
    ],
    faculties: [
      { text: 'Facultad de Ciencias de la Salud', value: 0 },
      { text: 'Facultad de Ciencias Sociales y Humanidades', value: 1 },
      { text: 'Escuela Politécnica Superior', value: 2 }
    ],
    departments: [
      { text: 'Administración', value: 0 },
      { text: 'Rectorado', value: 1 },
      { text: 'Secretaría General', value: 2 },
      { text: 'Secretaría Académica', value: 3 },
      { text: 'Servicio de Alumnos', value: 4 },
      { text: 'Negociado de Admisión', value: 5 },
      { text: 'CEMU', value: 6 },
      { text: 'Oficina de Relaciones Internacionales', value: 7 },
      { text: 'Gabinete de Calidad', value: 8 },
      { text: 'Gabinete de Comunicación', value: 9 },
      { text: 'OTRI', value: 10 },
      { text: 'Recepción - Conserjería', value: 11 },
      { text: 'Webmáster', value: 12 },
      { text: 'Ordenación Académica', value: 13 },
      { text: 'Biblioteca', value: 14 },
    ],
    accreditationAgencies: [
      { text: 'ACC', value: 0 },
      { text: 'ACCUEE', value: 1 },
      { text: 'ACPUA', value: 2 },
      { text: 'ACSUCYL', value: 3 },
      { text: 'ACSUG', value: 4 },
      { text: 'ANECA', value: 5 },
      { text: 'AQU Catalunya', value: 6 },
      { text: 'AQUIB', value: 7 },
      { text: 'AVAP', value: 8 },
      { text: 'Fundación para el Conocimiento madri+d', value: 9 },
      { text: 'UNIBASQ', value: 10 }
    ],
    civilStateSelect: [
      { text: 'Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente y sometidos a patria potestad prorrogada o rehabilitada que conviven exclusivamente con VD., sin convivir también con el otro progenitor', value: 0 },
      { text: 'Casado/a y no separado legalmente cuyo cónyuge no obtiene rentas superiores a 1.500€ anuales, excluidas las exentas', value: 1 },
      { text: 'Situacion familiar distinta de las dos anteriores', value: 2 },
    ],
    sexSelect: [
      { text: 'Mujer', value: 0 },
      { text: 'Hombre', value: 1 },
      { text: 'Otros', value: 2 },
    ],
    maximumStudiesSelect: [
      { text: 'Doctorado universitario', value: 0 },
      { text: 'Grado de más de 240 ECTS**, Licenciatura, Arquitectura, Ingeniería, Másteres, especialidad en Ciencias de la Salud y Equivalentes', value: 1 },
      { text: 'Grado de 240 ECTS, Diplomatura, Arquitectura e Ingeniería Técnicas y equivalentes; postgrado universitario de menos de 1 año', value: 2 },
      { text: 'Ciclo Formativo de Grado Superior, FPII, y equivalentes; título propio universitario de 2 o más años que requiere bachillerato', value: 3 },
      { text: 'Bachiller, BUP, COU, Bachiller Superior, Ciclo Formativo de Grado Medio, FPI, Grado Medio/Profesional de Música y Danza, FP Básica, y similares, EO Idiomas - nivel avanzado', value: 4 },
      { text: 'Otros estudios', value: 5 }
    ],
    dedicationSelect: [
      { text: 'Exclusiva', value: 0 },
      { text: 'Asociado', value: 1 },
    ],
    titulationSelect: [
      { text: 'Bachiller o equivalente', value: 0 },
      { text: 'Ciclo Formativo de Grado Medio o equivalente', value: 1 },
      { text: 'Ciclo Formativo de Grado Superior o equivalente', value: 2 },
      { text: 'Grado / Licenciatura / Diplomatura', value: 3 },
      { text: 'Master', value: 4 },
      { text: 'Doctorado', value: 5 },
      { text: 'Otros', value: 6 }
    ],
    personInformation: {
      name: null,
      lastName: null,
      idDocument: null,
      socialSecurity: null,
      nationality: null,
      line: null,
      city: null,
      division: null,
      country: null,
      dateOfBirth: null,
      postalCode: null,
      civilState: null,
      partnerIdDocument: null,
      sex: null,
      phoneNumber: null,
      mobilePhoneNumber: null,
      personalEmail: null,
      institutionalEmail: null,
      discapacityLevel: null,
      discapacityNeedsHelp: false,
      academics: {
        maximumStudies: null,
        accreditationAgency: null,
        titulation: null,
      },
      professional: {
        type: null,
        faculty: null,
        department: null,
        field: null,
        position: null
      },
    },
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Apellidos', value: 'lastName' },
      { text: 'NIE/NIF', value: 'idDocument' },
      { text: 'Numero telefono movil', value: 'mobilePhoneNumber' },
      { text: 'Email institucional', value: 'institutionalEmail' },
      { text: '', value: 'controls', sortable: false },
    ],
    items: [],
  }),
  watch: {
    tableOptions: {
      async handler() {
        this.tableLoading = true
        const { page, itemsPerPage } = this.tableOptions
        const skip = page > 1 ? itemsPerPage * page : 0

        await this.fetchPersons(itemsPerPage, skip)
      },
      deep: true
    }
  },
  methods: {
    getPerson(id) {
      this.$router.push({ path: `/person/${id}` });
    },
    async fetchPersons(limit = 20, skip = 0, startId = null) {
      const { data: { data, total } } = await this.$http.get('/persons', { params: { limit, skip, startId } });

      this.items = data
      this.tableTotalAmount = total
      this.tableLoading = false
    },
    async addPerson() {
      try {
        const formData = new FormData()

        Object.keys(this.personInformation).forEach(
          key => {
            if (key === 'academics' || key === 'professional') {
              formData.append(key, JSON.stringify(this.personInformation[key]))
            }

            else {
              if (this.personInformation[key] !== null) {
                formData.append(key, this.personInformation[key])
              }
            }
          }
        )

        if (this.profilePicture !== null) formData.append('profile', this.profilePicture)

        this.documents.forEach(document => formData.append(document.name, document))

        await this.$http.post('/persons', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.createPersonDialog = false;
        await this.fetchPersons();
      } catch (e) {
        this.createPersonDialog = false;
        this.errorText = e;
        this.errorDisplay = true;
      }
    },
    async deletePerson(id) {
      try {
        await this.$http.delete(`/persons/${id}`)

        await this.fetchPersons();
      } catch (e) {
        this.errorText = e;
        this.errorDisplay = true;
      }
    },
    async exportToXlsx() {
      try {
        const { data } = await this.$http.get('/persons/export', {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Personas-RRHH-${Date.parse(new Date().toString()).toString()}.xlsx`);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } catch (e) {
        this.errorText = e;
        this.errorDisplay = true;
      }
    }
  },
};
</script>
