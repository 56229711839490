<template>
  <div>
    <h1>Contrato beca de doctorado</h1>
    <br />
    <v-select
      outlined
      label="Nivel"
      :items="levelPicker"
      v-model="phdInternshipContract.level"
    />
    <v-select
      outlined
      label="Titulación"
      :items="titulationTypePicker"
      v-model="phdInternshipContract.titulation"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha inicio"
      v-mask="'####-##-##'"
      v-model="phdInternshipContract.startDate"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha fin"
      v-mask="'####-##-##'"
      v-model="phdInternshipContract.endDate"
    />
    <v-text-field
      outlined
      type="number"
      label="Importe beca"
      :rules="[rules.required, rules.numericValue]"
      v-model="phdInternshipContract.internshipAmount"
    />
    <v-text-field
      outlined
      type="number"
      label="% Exención de tasas"
      :rules="[rules.required, rules.numericValue]"
      v-model="phdInternshipContract.feeExemption"
    />
    <v-checkbox
      label="Beca residencia"
      v-model="phdInternshipContract.residencyInternship"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    startDate: false,
    endDate: false,
    rules: {
      required: value => !!value || 'Campo requerido',
      numericValue: value => (Number.isInteger(Number(value)) && Number(value) > 0) || 'Campo numerico'
    },
    levelPicker: [
      { text: 'Grado', value: 0 },
      { text: 'Máster', value: 1 },
      { text: 'Doctorado', value: 2 }
    ],
    titulationTypePicker: [
      { text: 'Oficial', value: 0 },
      { text: 'Título propio', value: 1 }
    ],
  }),
  computed: {
    ...mapState(['phdInternshipContract'])
  }
}
</script>

<style>

</style>
