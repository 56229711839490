<template>
  <v-container>
    <div v-if="bankAccounts.length > 0">
      <v-data-table :headers="headers" :items="bankAccounts">
        <template v-slot:item.controls="{ item }">
          <v-btn text @click.stop="openEditBankAccountDialog(item)">Editar</v-btn>
          <v-btn text color="red" @click="removeBankAccount(item.id)">Eliminar</v-btn>
          <attached-documents :resource="item.id" />
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h2>Esta persona no tiene cuentas bancarias</h2>
    </div>
    <v-dialog
      v-model="addBankAccountDialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Agregar cuenta bancaria</v-btn>
      </template>
      <v-card>
        <v-card-title>Agregar cuenta bancaria</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field outlined label="Nombre" v-model="addBankAccountInfo.name" />
            <v-text-field outlined label="IBAN" v-model="addBankAccountInfo.iban" />
            <v-file-input v-model="documents" outlined chips multiple label="Documentos a adjuntar" show-size />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="addBankAccount">Agregar</v-btn>
          <v-btn text @click="addBankAccountDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editBankAccountDialog"
      persistent
    >
      <v-card>
        <v-card-title>Editar cuenta bancaria</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field outlined label="Nombre" v-model="editBankAccountInfo.name" />
            <v-text-field outlined label="IBAN" v-model="editBankAccountInfo.iban" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="editBankAccount">Editar</v-btn>
          <v-btn text @click="editBankAccountDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AttachedDocumentsDialog from '../components/AttachedDocumentsDialog.vue'

export default {
  props: ['id'],
  async beforeMount() {
    const { data } = await this.$http.get(`/persons/${this.id}/bank-accounts`);

    this.bankAccounts = data;
  },
  components: {
    'attached-documents': AttachedDocumentsDialog,
  },
  data() {
    return {
      editBankAccountDialog: false,
      documents: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'IBAN', value: 'iban' },
        { text: '', value: 'controls', sortable: false },
      ],
      addBankAccountDialog: false,
      bankAccounts: [],
      addBankAccountInfo: {
        name: null,
        iban: null,
      },
      editBankAccountInfo: {
        id: null,
        name: null,
        iban: null,
      },
    };
  },
  methods: {
    async addBankAccount() {
      try {
        const formData = new FormData()
        const object = { ...this.addBankAccountInfo, person: this.id }

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        this.documents.forEach(document => formData.append(document.name, document))

        const { data } = await this.$http.post('/bank-accounts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        this.bankAccounts.push(data);

        this.addBankAccountDialog = false;
        this.addBankAccountInfo.name = null;
        this.addBankAccountInfo.iban = null;
        this.documents = []
      } catch {
        this.addBankAccountDialog = false;
      }
    },
    async removeBankAccount(id) {
      try {
        await this.$http.delete(`/bank-accounts/${id}`);

        this.bankAccounts = this.bankAccounts.filter((bankAccount) => bankAccount.id !== id);
      } catch {}
    },
    openEditBankAccountDialog(item) {
      this.editBankAccountInfo.id = item.id;
      this.editBankAccountInfo.name = item.name;
      this.editBankAccountInfo.iban = item.iban;
      this.editBankAccountDialog = true;
    },
    async editBankAccount() {
      try {
        const { id, ...rest } = this.editBankAccountInfo;
        const { data } = await this.$http.patch(`/bank-accounts/${id}`, {
          ...rest,
        });

        this.editBankAccountDialog = false;
        this.editBankAccountInfo.id = null;
        this.editBankAccountInfo.name = null;
        this.editBankAccountInfo.iban = null;

        this.bankAccounts.splice(this.bankAccounts.findIndex((x) => x.id === id), 1, data);
      } catch {
        this.editBankAccountDialog = false;
      }
    },
  },
}
</script>

<style>

</style>
