<template>
  <div>
    <h1>Contracto comercial</h1>
    <br />
    <v-select
      outlined
      v-model="contract.entity"
      :items="contractEntities"
      label="Entidad contratante"
    />
    <v-select
      outlined
      v-model="contract.location"
      :items="contractLocations"
      label="Ubicacion"
    />
    <v-select
      outlined
      v-model="contract.modality"
      :items="contractModalities"
      label="Modalidad"
    />
    <v-checkbox label="Contrato indefinido" v-model="contract.undefinedEndDate" />
    <v-text-field
      outlined
      label="Número de horas de la actividad"
      type="number"
      v-model="contract.hours"
    />
    <v-row>
      <v-col>
        <v-text-field
          outlined
          label="Fecha inicio contracto"
          placeholder="YYYY-MM-DD"
          v-mask="'####-##-##'"
          v-model="contract.startDate"
        />
      </v-col>
      <v-col>
        <v-text-field
          outlined
          label="Fecha final contracto"
          placeholder="YYYY-MM-DD"
          v-mask="'####-##-##'"
          v-model="contract.endDate"
        />
      </v-col>
    </v-row>
    <div v-if="contract.modality === 'Empresa'">
      <v-text-field outlined label="Nombre sociedad" v-model="contract.societyName" />
      <v-text-field outlined label="CIF" v-model="contract.societyCif" />
      <v-text-field outlined label="Domicilio social" v-model="contract.societyRegisteredOffice" />
      <v-text-field
        outlined
        label="Fecha de constitucion de sociedad"
        placeholder="YYYY-MM-DD"
        v-mask="'####-##-##'"
        v-model="contract.societyIncorporationDate"
      />
      <v-text-field
        outlined
        label="Notario constitución"
        v-model="contract.societyConstitutionNotary"
      />
      <v-text-field outlined label="N. Protocolo" v-model="contract.societyProtocolNumber" />
      <v-text-field
        outlined
        label="Inscripción registro mercantil"
        v-model="contract.societyCommercialRegistry"
      />
      <v-text-field
        outlined
        label="Fecha impresion"
        placeholder="YYYY-MM-DD"
        v-mask="'####-##-##'"
        v-model="contract.printDate"
      />
    </div>
    <v-select
      outlined
      v-model="contract.department"
      :items="contractDeparments"
      label="Departamento o servicio"
    />
    <v-text-field
      outlined
      v-model="contract.renumeration"
      type="number"
      label="Renumeracion"
    />
    <v-combobox
      multiple
      outlined
      append-icon
      v-model="contract.subjects"
      label="Asignaturas a impartir" chips deletable-chips
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    startDate: false,
    endDate: false,
    startDateModal: false,
    endDateModal: false,
    contractEntities: [
      'UNEATLANTICO',
      'CITICAN',
      'MLS JOURNALS',
      'PROIBERO',
      'FUNIBER',
      'Otros',
    ],
    contractLocations: [
      'Santander',
      'Barcelona',
      'Teletrabajo',
      'Otros',
    ],
    contractModalities: [
      'Autonomo',
      'Empresa',
      'Cuenta ajena',
      'Otros',
    ],
    types: [
      'Personal docente',
      'Personal investigador',
      'Personal administraction y servicio',
    ],
    contractDeparments: [
      'Recepción',
      'Negociado de Admisión',
      'Secretaría Académica',
      'Servicio de Alumnos',
      'Oficina de Relaciones Internacionales',
      'CEMU',
      'Administración',
      'Gabinete de Calidad y Estudios',
      'Biblioteca',
      'Servicio de Actividad Física y Deportes',
      'Gabinete de Comunicación',
      'Rectorado',
      'Servicios Jurídicos',
      'Secretaría General',
      'GMIDE',
      'Laboratorios',
      'Servicios informáticos / webmaster',
      'Facultad de Ciencias Sociales y Humanidades',
      'Facultad de Ciencias de la Salud',
      'Escuela Politécnica Superior',
      'Vicerrectorado de Ordenación Académica y Profesorado',
      'Extensión Universitaria',
      'Revista científica',
      'Dirección Residencia',
      'Limpieza Residencia',
      'Conserjería Residencia',
      'Recepción Residencia',
      'Instalaciones deportivas residencia',
    ],
  }),
  computed: {
    ...mapState({ contract: 'commercialContract' }),
  },
};
</script>

<style>

</style>
