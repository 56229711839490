<template>
  <v-dialog v-model="openDialog" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text @click="fetchAttachedDocuments">Ver documentos adjuntos</v-btn>
    </template>
    <v-card>
      <v-card-title>Documentos adjuntos</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="documents">
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleDateString('es-ES') }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="openDialog = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['resource'],
  data: () => ({
    openDialog: false,
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Fecha', value: 'createdAt' }
    ],
    documents: []
  }),
  methods: {
    async fetchAttachedDocuments() {
      try {
        const { data } = await this.$http.get(`/attachments/${this.resource}`)

        this.documents = data
      } catch {}
    },
  }
}
</script>

<style>

</style>
