var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Configuración")]),_c('br'),_c('v-divider'),_c('br'),_c('h2',[_vm._v("Selectores")]),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.selectors,"show-expand":""},scopedSlots:_vm._u([{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openEditSelectorDialog(item)}}},[_vm._v("Editar")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.deleteSelector(item.id)}}},[_vm._v("Eliminar")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{staticClass:"mr-2"},[_vm._v("Valores")]),_vm._l((item.values),function(value){return _c('v-chip',{key:value.id,staticClass:"mr-2"},[_vm._v(_vm._s(value.name))])})],2)]}}])}),_c('v-dialog',{attrs:{"scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v("Crear selector")])]}}]),model:{value:(_vm.createSelectorDialog),callback:function ($$v) {_vm.createSelectorDialog=$$v},expression:"createSelectorDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"outlined":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Agregar selector")])],1),_c('v-card-text',[_c('v-container',[_c('br'),_c('v-text-field',{attrs:{"outlined":"","label":"Nombre"},model:{value:(_vm.createSelectorName),callback:function ($$v) {_vm.createSelectorName=$$v},expression:"createSelectorName"}}),_c('v-switch',{attrs:{"label":"Activo"},model:{value:(_vm.createSelectorActive),callback:function ($$v) {_vm.createSelectorActive=$$v},expression:"createSelectorActive"}}),_c('v-combobox',{attrs:{"chips":"","clearable":"","multiple":"","append-icon":"","outlined":"","label":"Valores"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.createSelectorValues),callback:function ($$v) {_vm.createSelectorValues=$$v},expression:"createSelectorValues"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.createSelector}},[_vm._v("Crear")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createSelectorDialog = false}}},[_vm._v("Cancelar")])],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":""},model:{value:(_vm.editSelectorDialog),callback:function ($$v) {_vm.editSelectorDialog=$$v},expression:"editSelectorDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"outlined":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Editar selector")])],1),_c('v-card-text',[_c('v-container',[_c('br'),_c('v-text-field',{attrs:{"outlined":"","label":"Nombre"},model:{value:(_vm.createSelectorName),callback:function ($$v) {_vm.createSelectorName=$$v},expression:"createSelectorName"}}),_c('v-switch',{attrs:{"label":"Activo"},model:{value:(_vm.createSelectorActive),callback:function ($$v) {_vm.createSelectorActive=$$v},expression:"createSelectorActive"}}),_c('v-combobox',{attrs:{"chips":"","clearable":"","multiple":"","append-icon":"","outlined":"","label":"Valores"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.createSelectorValues),callback:function ($$v) {_vm.createSelectorValues=$$v},expression:"createSelectorValues"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.editSelector}},[_vm._v("Editar")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeEditSelectorDialog}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }