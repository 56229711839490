<template>
  <div>
    <h1 v-show="!edit">Contrato laboral</h1>
    <br />
    <v-select
      outlined
      v-model="laboralContract.entity"
      :items="contractEntities"
      label="Entidad contratante"
    />
    <v-select
      outlined
      v-model="laboralContract.modality"
      :items="contractModalities"
      label="Modalidad de contrato"
    />
    <v-text-field
      outlined
      v-model="laboralContract.professionalGroup"
      label="Grupo profesional"
    />
    <v-text-field
      outlined
      v-model="laboralContract.category"
      label="Categoria"
    />
    <v-select
      outlined
      v-model="laboralContract.dedication"
      :items="contractDedications"
      label="Dedicacion"
    />
    <v-select
      outlined
      :items="contractLocations"
      v-model="laboralContract.location"
      label="Centro de trabajo"
    />
    <v-select
      outlined
      :items="contractSchedules"
      v-model="laboralContract.schedule"
      label="Jornada"
    />
    <v-text-field
      outlined
      type="number"
      label="Horas jornada"
      :rules="[rules.required, rules.numericValue]"
      v-model.number="laboralContract.hours"
    />
    <v-checkbox label="Contrato indefinido" v-model="laboralContract.undefinedEndDate" />
    <v-row>
      <v-col>
        <v-text-field
          outlined
          label="Fecha inicio"
          placeholder="YYYY-MM-DD"
          v-mask="'####-##-##'"
          v-model="laboralContract.startDate"
        />
      </v-col>
      <v-col>
        <v-text-field
          outlined
          label="Fecha fin"
          placeholder="YYYY-MM-DD"
          v-mask="'####-##-##'"
          v-model="laboralContract.endDate"
        />
      </v-col>
    </v-row>
    <v-menu
      ref="trialPeriod"
      v-model="trialPeriod"
      :return-value.sync="laboralContract.trialPeriod"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="laboralContract.trialPeriod"
          label="Periodo de prueba"
          outlined
          v-bind="attrs"
          v-on="on" />
      </template>
      <v-date-picker v-model="laboralContract.trialPeriod" no-title scrollable range>
        <v-spacer />
        <v-btn text @click="modal = false">Cancelar</v-btn>
        <v-btn text @click="$refs.trialPeriod.save(laboralContract.trialPeriod)">Aceptar</v-btn>
      </v-date-picker>
    </v-menu>
    <v-text-field
      outlined
      type="number"
      :rules="[rules.required, rules.numericValue]"
      v-model="laboralContract.anualRetribution"
      label="Retribución anual"
    />
    <v-select
      outlined
      :items="contractCollectiveAgreements"
      v-model="laboralContract.collectiveAgreement"
      label="Convenio de aplicación"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['edit'],
  data: () => ({
    rules: {
      required: value => !!value || 'Campo requerido',
      numericValue: value => (Number.isInteger(Number(value)) && Number(value) > 0) || 'Campo numerico'
    },
    startHour: false,
    startHourFlag: false,
    endHour: false,
    endHourFlag: false,
    trialPeriod: false,
    startDate: false,
    endDate: false,
    startDateModal: false,
    endDateModal: false,
    contractEntities: [
      'UNEATLANTICO',
      'CITICAN',
      'MLS JOURNALS',
      'PROIBERO',
      'FUNIBER'
    ],
    contractCollectiveAgreements: [
      { text: 'XIII CONVENIO DE ÁMBITO ESTATAL PARA LOS CENTROS DE EDUACACIÓN UNIVERSITARIA E INVESTIGACIÓN', value: 0 },
      { text: 'CONVENIO COLECTIVO NACIONAL DEL CICLO DEL COMERCIO DEL PAPEL Y ARTES GRÁFICAS', value: 1 },
      { text: 'CONVENIO COLECTIVO PARA EL SECTOR DE LA HOSTELERÍA DE LA COMUNIDAD AUTÓNOMA DE CANTABRIA', value: 2 }
    ],
    contractModalities: [
      { text: 'Obra o servicio', value: 0 },
      { text: 'Eventual por circunstancias de la producción', value: 1 },
      { text: 'Interinidad', value: 2 },
      { text: 'Prácticas', value: 3 }
    ],
    contractLocations: [
      'Santander',
      'Barcelona',
      'Otros'
    ],
    contractSchedules: [
      { text: 'Tiempo completo', value: 0 },
      { text: 'Tiempo parcial', value: 1 }
    ],
    contractDedications: [
      { text: 'Exclusiva', value: 0 },
      { text: 'Plena', value: 1 },
      { text: 'Ninguna', value: 2 }
    ],
  }),
  computed: {
    ...mapState({ laboralContract: 'laboralContract' }),
  }
};
</script>

<style>

</style>
