<template>
  <v-container>
    <div v-if="addresses.length > 0">
      <v-data-table :headers="headers" :items="addresses">
        <template v-slot:item.type="{ item }">
          {{ typePicker[item.type].text }}
        </template>
        <template v-slot:item.active="{ item }">
          {{ item.id === activeAddress ? 'Si' : 'No' }}
        </template>
        <template v-slot:item.controls="{ item }">
          <v-btn text color="red" @click="removeAddress(item.id)">Eliminar</v-btn>
          <v-btn text @click="setActiveAddress(item.id)">Marcar como activa</v-btn>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h2>Esta persona no tiene direcciones guardadas</h2>
    </div>
    <v-dialog
      v-model="addAddressDialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Agregar direccion</v-btn>
      </template>
      <v-card>
        <v-card-title>Agregar direccion</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field outlined label="Nombre" v-model="addAddressInfo.name" />
            <v-text-field outlined label="Direccion" v-model="addAddressInfo.line" />
            <v-text-field outlined label="Ciudad" v-model="addAddressInfo.city" />
            <v-text-field outlined label="Provincia" v-model="addAddressInfo.division" />
            <v-text-field outlined label="Pais" v-model="addAddressInfo.country" />
            <v-text-field outlined label="Codigo postal" v-model="addAddressInfo.postalCode" />
            <v-select outlined :items="typePicker" label="Tipo" v-model="addAddressInfo.type" />
            <v-file-input v-model="documents" outlined chips multiple label="Documentos a adjuntar" show-size />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="addAddress">Agregar</v-btn>
          <v-btn text @click="addAddressDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    activeAddress: {
      type: String,
      default: null,
    },
  },
  async beforeMount() {
    const { data } = await this.$http.get(`/persons/${this.id}/addresses`);

    this.addresses = data;
  },
  data() {
    return {
      addresses: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Direccion', value: 'line' },
        { text: 'Ciudad', value: 'city' },
        { text: 'Provincia', value: 'division' },
        { text: 'Pais', value: 'country' },
        { text: 'Codigo postal', value: 'postalCode' },
        { text: 'Tipo', value: 'type' },
        { text: 'Activa/principal', value: 'active' },
        { text: '', value: 'controls', sortable: false },
      ],
      documents: [],
      addAddressDialog: false,
      addAddressInfo: {
        name: null,
        line: null,
        city: null,
        division: null,
        country: null,
        postalCode: null,
        type: 0,
      },
      typePicker: [
        { text: 'Postal', value: 0 },
        { text: 'Fisica', value: 1 },
        { text: 'Ambas', value: 2 },
      ],
    };
  },
  methods: {
    async addAddress() {
      try {
        const formData = new FormData()
        const object = { ...this.addAddressInfo, person: this.id }

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        this.documents.forEach(document => formData.append(document.name, document))

        const { data } = await this.$http.post('/addresses', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        this.addresses.push(data);

        this.addAddressDialog = false;
        this.addAddressInfo.name = null;
        this.addAddressInfo.line = null;
        this.addAddressInfo.type = 0;
        this.documents = []
      } catch {
        this.addAddressDialog = false;
      }
    },
    async removeAddress(id) {
      try {
        const { data } = await this.$http.delete(`/addresses/${id}`);

        this.addresses = this.addresses.filter((address) => address.id !== id);
      } catch {}
    },
    async setActiveAddress(id) {
      try {
        const { data } = await this.$http.get(`/addresses/${id}/actions/principal`);
        this.addresses.splice(this.addresses.findIndex((address) => address.id === id), 1, data);

        this.$emit('set-active-address', data);
      } catch {}
    }
  },
};
</script>

<style>
.row {
  margin: 0 !important;
}
</style>
