<template>
  <div class="header-row">
      <div class="header-col">
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div class="header-col">
        <h1>{{ header }}</h1>
      </div>
    </div>
</template>

<script>
export default {
  props: ['header']
};
</script>

<style>
.header-row {
  display: flex;
}

.header-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px
}
</style>
