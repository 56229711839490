<template>
  <v-container>
    <div v-if="incidents.length > 0">
      <v-data-table :headers="headers" :items="incidents">
        <template v-slot:item.severity="{ item }">
          {{ severityPicker[item.severity].text  }}
        </template>
        <template v-slot:item.controls="{ item }">
          <v-btn text @click="openEditIncidentDialog(item)">Editar</v-btn>
          <v-btn text color="red" @click="removeIncident(item.id)">Eliminar</v-btn>
      </template>
      </v-data-table>
      <v-dialog v-model="editIncidentDialog" persistent>
        <v-card>
          <v-card-title>Editar incidente</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field outlined label="Descripcion" v-model="editIncidentInfo.description" />
              <v-select
                outlined
                label="Gravedad"
                :items="severityPicker"
                v-model="editIncidentInfo.severity"
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="editIncident">Editar</v-btn>
            <v-btn text @click="editIncidentDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h2>Esta persona no tiene incidentes.</h2>
    </div>
    <v-dialog
        v-model="addIncidentDialog"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">Agregar incidente</v-btn>
        </template>
        <v-card>
          <v-card-title>Agregar incidente</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field outlined label="Descripcion" v-model="addIncidentInfo.description" />
              <v-select
                outlined
                label="Gravedad"
                :items="severityPicker"
                v-model="addIncidentInfo.severity"
              />
              <v-text-field
                outlined
                v-mask="'####-##-##'"
                label="Fecha inicio"
                placeholder="YYYY-MM-DD"
                v-model="addIncidentInfo.startDate"
              />
              <v-text-field
                outlined
                v-mask="'####-##-##'"
                label="Fecha fin"
                placeholder="YYYY-MM-DD"
                v-model="addIncidentInfo.endDate"
              />
            </v-container>
            <v-file-input v-model="documents" outlined chips multiple label="Documentos a adjuntar" show-size />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="addIncident">Agregar</v-btn>
            <v-btn text @click="addIncidentDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      addIncidentDialog: false,
      editIncidentDialog: false,
      headers: [
        { text: 'Descripción', value: 'description' },
        { text: 'Gravedad', value: 'severity' },
        { text: 'Fecha inicio', value: 'startDate' },
        { text: 'Fecha fin', value: 'endDate' },
        { text: '', value: 'controls', sortable: false }
      ],
      editIncidentInfo: {
        id: null,
        description: null,
        severity: null,
        startDate: null,
        endDate: null
      },
      addIncidentInfo: {
        description: null,
        severity: null,
        startDate: null,
        endDate: null
      },
      severityPicker: [
        { text: 'Baja', value: 0 },
        { text: 'Media', value: 1 },
        { text: 'Alta', value: 2 },
      ],
      incidents: [],
      documents: []
    };
  },
  async beforeMount() {
    await this.fetchIncidents();
  },
  methods: {
    openEditIncidentDialog(item) {
      this.editIncidentDialog = true;
      this.editIncidentInfo.id = item.id;
      this.editIncidentInfo.description = item.description;
      this.editIncidentInfo.severity = item.severity;
    },
    async removeIncident(id) {
      try {
        await this.$http.delete(`/incidents/${id}`);

        this.incidents = this.incidents.filter((incident) => incident.id !== id);
      } catch {}
    },
    async addIncident() {
      try {
        const formData = new FormData()
        const object = { ...this.addIncidentInfo, person: this.id }

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        this.documents.forEach(document => formData.append(document.name, document))

        const { data } = await this.$http.post('/incidents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.incidents.push(data);

        this.addIncidentDialog = false;
        this.addIncidentInfo.description = null;
        this.addIncidentInfo.severity = null;
        this.documents = []
      } catch {
        this.addIncidentDialog = false;
      }
    },
    async editIncident() {
      try {
        const { id, ...rest } = this.editIncidentInfo;
        const { data } = await this.$http.patch(`/incidents/${id}`, {
          ...rest,
        });

        this.editIncidentDialog = false;
        this.editIncidentInfo.id = null;
        this.editIncidentInfo.description = null;
        this.editIncidentInfo.severity = null;

        this.incidents.splice(this.incidents.findIndex((x) => x.id === id), 1, data);
      } catch {
        this.editIncidentDialog = false;
      }
    },
    async fetchIncidents() {
      const { data } = await this.$http.get(`/persons/${this.id}/incidents`);

      this.incidents = data;
    },
  },
};
</script>

<style>
.row {
  margin: 0 !important;
}

.incident-card:not(:last-child) {
  margin-bottom: 2rem;
}
</style>
