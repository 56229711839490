<template>
  <v-app>
    <v-app-bar app>
      <router-link to="/">
        <h3>RRHH Prototipo</h3>
      </router-link>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
  }),
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
