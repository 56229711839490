import Vue from 'vue';
import Vuex from 'vuex';

import commercialContract from './commercial-contract.js'
import laboralContract from './laboral-contract.js'
import adHonoremContract from './ad-honorem-contract.js'
import collaborationInternshipContract from './collaboration-internship-contract.js'
import phdInternshipContract from './phd-internship-contract.js'
import stayContract from './stay-contract.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({}),
  mutations: {},
  actions: {},
  modules: {
    commercialContract,
    laboralContract,
    adHonoremContract,
    collaborationInternshipContract,
    phdInternshipContract,
    researchStayContract: stayContract,
    facultyStayContract: stayContract
  },
});
