const initialState = () => ({
  proccedenceEntity: null,
  department: null,
  responsablePerson: null,
  startDate: null,
  endDate: null
})

const stayContract = {
  namespaced: true,
  state: initialState,
  mutations: {
    setProccedenceEntity: (state, payload) => state.proccedenceEntity = payload,
    setDepartment: (state, payload) => state.department = payload,
    setResponsablePerson: (state, payload) => state.responsablePerson = payload,
    setStartDate: (state, payload) => state.startDate = payload,
    setEndDate: (state, payload) => state.endDate = payload,
    reset: (state) => Object.assign(state, initialState()),
    setAll: (state, payload) => Object.assign(state, payload)
  }
}

export default stayContract
