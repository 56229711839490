<template>
  <v-container>
    <h2>Acreditaciones</h2>
    <br />
    <div v-if="accreditations.length > 0">
      <v-data-table
        :items="accreditations"
        :headers="headers"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 20]
        }"
      >
        <template v-slot:item.controls="{ item }">
          <v-btn text color="red" @click="deleteAccreditation(item.id)">Eliminar</v-btn>
        </template>
        <template v-slot:item.evaluationType="{ item }">
          {{ evaluationTypeSelect[item.evaluationType].text }}
        </template>
        <template v-slot:item.date="{ item }">
         {{ (new Date(Date.parse(item.date))).toLocaleDateString('es-ES') }}
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h2>Esta persona no tiene acreditaciones.</h2>
    </div>
    <v-dialog v-model="addAccreditationDialog" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Agregar acreditación</v-btn>
      </template>
      <v-card>
        <v-card-title>Agregar acreditación</v-card-title>
        <v-card-text>
          <v-container>
            <v-select
              outlined
              label="Tipo de Evaluación"
              :items="evaluationTypeSelect"
              v-model="addAccreditationInfo.evaluationType"
            />
            <v-text-field
              outlined
              label="Agencia Acreditante"
              v-model="addAccreditationInfo.accreditationAgency"
            />
            <v-text-field
              outlined
              label="Ciudad agencia acreditación"
              v-model="addAccreditationInfo.accreditationAgencyCity"
            />
            <v-text-field
              outlined
              label="Acreditación o reconocimiento"
              v-model="addAccreditationInfo.accreditation"
            />
            <v-text-field
              placeholder="YYYY-MM-DD"
              outlined
              label="Fecha acreditación"
              v-mask="'####-##-##'"
              v-model="addAccreditationInfo.date"
            />
            <v-file-input
              outlined
              v-model="documents"
              chips
              multiple
              label="Documentos a adjuntar"
              show-size
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="addAccreditation">Agregar</v-btn>
          <v-btn text @click="addAccreditationDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      accreditations: [],
      documents: [],
      headers: [
        { text: 'Tipo de Evaluación', value: 'evaluationType' },
        { text: 'Agencia acreditante', value: 'accreditationAgency' },
        { text: 'Ciudad de la agencia', value: 'accreditationAgencyCity' },
        { text: 'Fecha acreditación', value: 'date' },
        { text: '', value: 'controls', sortable: false }
      ],
      addAccreditationDialog: false,
      evaluationTypeSelect: [
        { text: 'Figura de profesor universitario contratado', value: 0 },
        { text: 'Evaluación de los tramos de investigación', value: 1 }
      ],
      addAccreditationInfo: {
        evaluationType: null,
        accreditationAgency: null,
        accreditationAgencyCity: null,
        accreditation: null,
        date: null,
      },
    }
  },
  async beforeMount() {
    await this.getPersonAccreditations()
  },
  methods: {
    async getPersonAccreditations() {
      try {
        const { data } = await this.$http.get(`/persons/${this.id}/accreditations`)

        this.accreditations = data
      } catch {}
    },
    async addAccreditation() {
      try {
        const formData = new FormData()
        const object = { ...this.addAccreditationInfo, person: this.id }

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        this.documents.forEach(document => formData.append(document.name, document))

        const { data } = await this.$http.post('/accreditations', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.accreditations.push(data)
        this.addAccreditationDialog = false
        this.addAccreditationInfo.evaluationType = null
        this.addAccreditationInfo.accreditationAgency = null
        this.addAccreditationInfo.accreditationAgencyCity = null
        this.addAccreditationInfo.accreditation =  null
        this.addAccreditationInfo.date = null
        this.documents = []
      } catch {
        this.addAccreditationDialog = false
      }
    },
    async deleteAccreditation(id) {
      try {
        const { data } = await this.$http.delete(`/accreditations/${id}`)

        this.accreditations = this.accreditations.filter((accreditation) => accreditation.id !== id)
      } catch {}
    },
  }
}
</script>

<style>

</style>
