<template>
  <v-container>
    <div v-if="permitsCasualties.length > 0">
      <v-data-table :headers="headers" :items="permitsCasualties" item-key="id">
        <template v-slot:item.type="{ item }">
          {{ permitPicker[item.type].text }}
        </template>
        <template v-slot:item.controls="{ item }">
          <v-btn text @click.stop="openEditPermitCasualtyDialog(item)">Editar</v-btn>
          <v-btn text color="red" @click="removePermit(item.id)">Eliminar</v-btn>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h2>No existen ausencias para esta persona</h2>
    </div>
    <v-dialog
      v-model="addPermitDialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Agregar ausencia</v-btn>
      </template>
      <v-card>
        <v-card-title>Agregar ausencia</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field outlined label="Nombre" v-model="addPermit.name" />
            <v-text-field outlined label="Descripcion" v-model="addPermit.description" />
            <v-select outlined label="Tipo" :items="permitPicker" v-model="addPermit.type" />
            <v-menu
              ref="startDate"
              v-model="startDate"
              :retur-value.sync="addPermit.startDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="addPermit.startDate"
                  label="Fecha inicio"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="addPermit.startDate" no-title scrollable>
                <v-spacer />
                <v-btn text @click="modal = false">Cancelar</v-btn>
                <v-btn text @click="$refs.startDate.save(addPermit.startDate)">Aceptar</v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="endDate"
              v-model="endDate"
              :retur-value.sync="addPermit.endDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="addPermit.endDate"
                  label="Fecha fin"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="addPermit.endDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn text @click="modal = false">Cancelar</v-btn>
                <v-btn text @click="$refs.endDate.save(addPermit.endDate)">Aceptar</v-btn>
              </v-date-picker>
            </v-menu>
            <v-file-input v-model="documents" outlined chips multiple label="Documentos a adjuntar" show-size />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="createPermit">Agregar</v-btn>
          <v-btn text @click="addPermitDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPermitCasualtyDialog" persistent>
      <v-card>
        <v-card-title>Editar permiso/baja</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field outlined label="Nombre" v-model="editPermitCasualtyInfo.name" />
            <v-text-field outlined label="Descripcion" v-model="editPermitCasualtyInfo.description" />
            <v-select outlined label="Tipo" :items="permitPicker" v-model="editPermitCasualtyInfo.type" />
            <v-menu
                ref="startDate"
                v-model="startDate"
                :retur-value.sync="editPermitCasualtyInfo.startDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editPermitCasualtyInfo.startDate"
                    label="Fecha inicio"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="editPermitCasualtyInfo.startDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text @click="modal = false">Cancelar</v-btn>
                  <v-btn text @click="$refs.startDate.save(editPermitCasualtyInfo.startDate)">Aceptar</v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="endDate"
                v-model="endDate"
                :retur-value.sync="editPermitCasualtyInfo.endDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editPermitCasualtyInfo.endDate"
                    label="Fecha fin"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="editPermitCasualtyInfo.endDate"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn text @click="modal = false">Cancelar</v-btn>
                  <v-btn text @click="$refs.endDate.save(editPermitCasualtyInfo.endDate)">Aceptar</v-btn>
                </v-date-picker>
              </v-menu>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="editPermitCasualty()">Editar</v-btn>
          <v-btn text @click="editPermitCasualtyDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ['id'],
  async beforeMount() {
    const { data } = await this.$http.get(`/persons/${this.id}/absences`);

    this.permitsCasualties = data;
  },
  data() {
    return {
      editPermitCasualtyDialog: false,
      editPermitCasualtyInfo: {
        id: null,
        name: null,
        description: null,
        startDate: null,
        endDate: null,
        type: null,
        duration: null
      },
      documents: [],
      addPermitDialog: false,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripcion', value: 'description' },
        { text: 'Tipo', value: 'type' },
        { text: 'Fecha inicio', value: 'startDate' },
        { text: 'Fecha fin', value: 'endDate' },
        { text: '', value: 'controls', sortable: false },
      ],
      permitsCasualties: [],
      permitPicker: [
        { text: 'Asuntos personales', value: 0 },
        { text: 'Baja IT', value: 1 },
        { text: 'Baja Accidente Laboral', value: 2 },
        { text: 'Consulta médica', value: 3 },
        { text: 'Deber de carácter público', value: 4 },
        { text: 'Dispensa académica', value: 5 },
        { text: 'Excedencia', value: 6 },
        { text: 'Exámenes oficiales', value: 7 },
        { text: 'Exámenes prenatales', value: 8 },
        { text: 'Fallecimiento familiar', value: 9 },
        { text: 'Gestiones', value: 10 },
        { text: 'Hospitalización familiar', value: 11 },
        { text: 'Intervención familiar sin hospitalización', value: 12 },
        { text: 'Lactancia', value: 13 },
        { text: 'Permiso Matrimonio', value: 14 },
        { text: 'Permiso Maternidad', value: 15 },
        { text: 'Permiso Paternidad', value: 16 },
        { text: 'Permiso sin sueldo', value: 17 },
        { text: 'Traslado de domicilio', value: 18 }
      ],
      startDate: false,
      endDate: false,
      addPermit: {
        name: null,
        description: null,
        startDate: null,
        endDate: null,
        type: 0,
      },
    };
  },
  methods: {
    async createPermit() {
      try {
        const formData = new FormData()
        const object = {
          person: this.id,
          name: this.addPermit.name || undefined,
          description: this.addPermit.description || undefined,
          startDate: this.addPermit.startDate || undefined,
          endDate: this.addPermit.endDate || undefined,
          type: this.addPermit.type,
        }

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        this.documents.forEach(document => formData.append(document.name, document))

        const { data } = await this.$http.post('/absences', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.permitsCasualties.push(data);

        this.addPermitDialog = false;
        this.addPermit.name = null;
        this.addPermit.description = null;
        this.addPermit.startDate = null;
        this.addPermit.endDate = null;
        this.addPermit.type = 0;
        this.documents = []
      } catch {
        this.addPermitDialog = false;
        this.addPermit.name = null;
        this.addPermit.description = null;
        this.addPermit.startDate = null;
        this.addPermit.endDate = null;
        this.addPermit.type = 0;
      }
    },
    async removePermit(id) {
      try {
        await this.$http.delete(`/absences/${id}`);

        this.permitsCasualties = this.permitsCasualties.filter((x) => x.id !== id);
      } catch {}
    },
    openEditPermitCasualtyDialog(item) {
      this.editPermitCasualtyInfo.id = item.id;
      this.editPermitCasualtyInfo.name = item.name;
      this.editPermitCasualtyInfo.description = item.description;
      this.editPermitCasualtyInfo.startDate = item.startDate;
      this.editPermitCasualtyInfo.endDate = item.endDate;
      this.editPermitCasualtyInfo.type = item.type;

      this.editPermitCasualtyDialog = true;
    },
    async editPermitCasualty() {
      try {
        const { id, ...rest } = this.editPermitCasualtyInfo;
        const { data } = await this.$http.patch(`/absences/${id}`, {
          ...rest
        });

        this.permitsCasualties.splice(
          this.permitsCasualties.findIndex((x) => x.id === id), 1, data
        );

        this.editPermitCasualtyDialog = false;
      } catch {
        this.editPermitCasualtyDialog = false;
      }
    },
  },
};
</script>

<style>
.row {
  margin: 0 !important;
}
</style>
