<template>
  <v-container>
    <h2>Documentos</h2>
    <br />
    <div v-if="documents.length > 0">
      <v-data-table :items="documents" :headers="headers">
        <template v-slot:item.controls="{ item }">
          <v-btn text @click="downloadDocument(item.name, item.id)">Descargar</v-btn>
          <v-btn text color="red" @click="openDeleteDocumentDialog(item.id)">Eliminar</v-btn>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ (new Date(item.createdAt)).toLocaleDateString('es-ES') }}
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h2>Esta persona todavia no tiene documentos generados o subidos.</h2>
    </div>
    <v-dialog v-model="deleteDocumentDialog" max-width="300">
      <v-card>
        <v-card-title>Eliminar documento</v-card-title>
        <v-card-text>
          ¿Esta seguro que quiere eliminar este documento? Esta accion no es reversible.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="deleteDocument">Borrar</v-btn>
          <v-btn text @click="deleteDocumentDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      documents: [],
      documentToDelete: null,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Fecha creación', value: 'createdAt' },
        { text: '', value: 'controls', sortable: false }
      ],
      deleteDocumentDialog: false,
    }
  },
  async beforeMount() {
    await this.getPersonDocuments();
  },
  methods: {
    async getPersonDocuments() {
      try {
        const { data } = await this.$http.get(`/persons/${this.id}/documents`)

        this.documents = data;
      } catch {}
    },
    async downloadDocument(name, id) {
      try {
        const { data } = await this.$http.get(`/documents/${id}`, {
          responseType: 'blob',
        })

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.pdf`);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } catch {

      }
    },
    openDeleteDocumentDialog(id) {
      this.deleteDocumentDialog = true;
      this.documentToDelete = id;
    },
    async deleteDocument() {
      try {
        await this.$http.delete(`/documents/${this.documentToDelete}`)

        this.deleteDocumentDialog = false;
        this.documents = this.documents.filter((document) => document.id !== this.documentToDelete)
      } catch {
          
      }
    }
  },
}
</script>

<style>

</style>
