import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Person from '../views/Person.vue';
import Configuration from '../views/Configuration.vue';
import PersonContracts from '../views/PersonContracts.vue';
import PersonIncidents from '../views/PersonIncidents.vue';
import PersonPermits from '../views/PersonPermits.vue';
import PersonAddresses from '../views/PersonAddresses.vue';
import PersonBankAccounts from '../views/PersonBankAccounts.vue';
import PersonEmergencyContacts from '../views/PersonEmergencyContacts.vue';
import PersonFamily from '../views/PersonFamily.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/person/:id',
    props: true,
    component: Person,
  },
  {
    path: '/person/:id/contracts',
    props: true,
    component: PersonContracts,
  },
  {
    path: '/person/:id/incidents',
    props: true,
    component: PersonIncidents,
  },
  {
    path: '/person/:id/permits',
    props: true,
    component: PersonPermits,
  },
  {
    path: '/person/:id/addresses',
    props: true,
    component: PersonAddresses,
  },
  {
    path: '/person/:id/bank-accounts',
    props: true,
    component: PersonBankAccounts,
  },
  {
    path: '/person/:id/emergency-contacts',
    props: true,
    component: PersonEmergencyContacts,
  },
  {
    path: '/person/:id/family',
    props: true,
    component: PersonFamily,
  },
  {
    path: '/configuration',
    component: Configuration,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
