<template>
  <div>
    <h1>Estancias profesorado</h1>
    <br />
    <v-text-field
      outlined
      label="Entidad de procedencia"
      v-model="facultyStayContract.proccedenceEntity"
    />
    <v-text-field
      outlined
      label="Departamento"
      v-model="facultyStayContract.department"
    />
    <v-text-field
      outlined
      label="Responsable en el centro de acogida"
      v-model="facultyStayContract.responsablePerson"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha inicio"
      v-model="facultyStayContract.startDate"
      v-mask="'####-##-##'"
    />
    <v-text-field
      outlined
      placeholder="YYYY-MM-DD"
      label="Fecha fin"
      v-model="facultyStayContract.startDate"
      v-mask="'####-##-##'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['facultyStayContract'])
  }
}
</script>
