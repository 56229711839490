<template>
  <v-expansion-panels flat>
    <v-expansion-panel @click="fetchAttachedDocuments">
      <v-expansion-panel-header>
        Documentos adjuntos
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table :headers="headers" :items="attachedDocuments">
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleDateString('es-ES') }}
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: ['resource'],
  data: () => ({
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Fecha', value: 'createdAt' }
    ],
    attachedDocuments: []
  }),
  methods: {
    async fetchAttachedDocuments() {
      try {
        const { data } = await this.$http.get(`/attachments/${this.resource}`)

        this.attachedDocuments = data
      } catch {}
    },
  }
}
</script>

<style>

</style>
