<template>
  <v-container>
    <HeaderBar :header="`${person.name} ${person.lastName}`" v-if="person" />
    <PersonContracts :id="person.id" :activeAddress="person.activeAddress" :emergencyContact="person.emergencyContact" />
    <br />
    <v-btn @click="generateModel145">Generar modelo 145</v-btn>
    <v-btn @click="exportPersonToXlsx">Exportar a Excel</v-btn>
    <v-btn @click="generateIncidentsReport">Generar reporte de incidencias</v-btn>
    <v-btn @click="generatePermitsCasualtiesReport">Generar reporte de ausencias</v-btn>
  </v-container>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue';
import PersonIncidents from '@/views/PersonIncidents.vue';
import PersonPermits from '@/views/PersonPermits.vue';
import PersonContracts from '@/views/PersonContracts.vue';
import PersonFamily from '@/views/PersonFamily.vue';
import PersonAddresses from '@/views/PersonAddresses.vue';
import PersonEmergencyContacts from '@/views/PersonEmergencyContacts.vue';
import PersonBankAccounts from '@/views/PersonBankAccounts.vue';
import PersonDocuments from '@/components/PersonDocuments.vue';
import PersonAccreditations from '@/components/PersonAccreditations.vue'

export default {
  props: ['id'],
  components: {
    HeaderBar,
    PersonIncidents,
    PersonPermits,
    PersonContracts,
    PersonFamily,
    PersonAddresses,
    PersonEmergencyContacts,
    PersonBankAccounts,
    PersonDocuments,
    PersonAccreditations
  },
  async beforeMount() {
    if (this.id !== undefined) {
      const { data } = await this.$http.get(`/persons/${this.id}`);

      this.person = data;
    } else {
      this.$router.replace({ path: '/' });
    }
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_API,
      documents: [],
      editProfilePicture: null,
      discapacityLevel: [
        { text: 'Ninguno', value: 0 },
        { text: 'Igual o superior al 33% e inferior al 65%', value: 33 },
        { text: 'Igual o superior al 65%', value: 65 }
      ],
      professionalTypes: [
        { text: 'Personal Docente e Investigador', value: 0 },
        { text: 'Personal de Administración y Servicios', value: 1 },
        { text: 'Otros', value: 2 }
      ],
      knowledgeFields: [
        { text: 'Ciencias exactas y naturales', value: 0 },
        { text: 'Ingeniería y tecnología', value: 1 },
        { text: 'Ciencias médicas', value: 2 },
        { text: 'Ciencias de la agricultura y veterinaria', value: 3 },
        { text: 'Ciencias sociales', value: 4 },
        { text: 'Humanidades y las artes', value: 5 }
      ],
      faculties: [
        { text: 'Facultad de Ciencias de la Salud', value: 0 },
        { text: 'Facultad de Ciencias Sociales y Humanidades', value: 1 },
        { text: 'Escuela Politécnica Superior', value: 2 }
      ],
      departments: [
        { text: 'Administración', value: 0 },
        { text: 'Rectorado', value: 1 },
        { text: 'Secretaría General', value: 2 },
        { text: 'Secretaría Académica', value: 3 },
        { text: 'Servicio de Alumnos', value: 4 },
        { text: 'Negociado de Admisión', value: 5 },
        { text: 'CEMU', value: 6 },
        { text: 'Oficina de Relaciones Internacionales', value: 7 },
        { text: 'Gabinete de Calidad', value: 8 },
        { text: 'Gabinete de Comunicación', value: 9 },
        { text: 'OTRI', value: 10 },
        { text: 'Recepción - Conserjería', value: 11 },
        { text: 'Webmáster', value: 12 },
        { text: 'Ordenación Académica', value: 13 },
        { text: 'Biblioteca', value: 14 },
      ],
      accreditationAgencies: [
        'ACC',
        'ACCUEE',
        'ACPUA',
        'ACSUCYL',
        'ACSUG',
        'ANECA',
        'AQU Catalunya',
        'AQUIB',
        'AVAP',
        'Fundación para el Conocimiento madri+d',
        'UNIBASQ'
      ],
      generateCommercialContractLoading: false,
      generateCommercialContractRetiredLoading: false,
      generateCommercialContractCompanyLoading: false,
      generateAdHonoremContractLoading: false,
      generateModel145Loading: false,
      headers: [
        { text: 'Nombre titulacion', value: 'name' },
        { text: 'Fecha', value: 'date' },
        { text: 'Tipo de titulacion', value: 'type' },
        { text: '', value: 'controls', sortable: false },
      ],
      person: undefined,
      editPersonalInfoDialog: false,
      editProfessionalInfoDialog: false,
      addTitulationDialog: false,
      removeTitulationDialog: false,
      removeTitulationId: null,
      titulationDateModal: false,
      editPersonalInfoDateModal: false,
      addTitulation: {
        type: null,
        name: null,
        date: null,
      },
      sexSelect: [
        { text: 'Mujer', value: 0 },
        { text: 'Hombre', value: 1 },
        { text: 'Otros', value: 2 },
      ],
      civilStateSelect: [
        { text: 'Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente y sometidos a patria potestad prorrogada o rehabilitada que conviven exclusivamente con VD., sin convivir también con el otro progenitor', value: 0 },
        { text: 'Casado/a y no separado legalmente cuyo cónyuge no obtiene rentas superiores a 1.500€ anuales, excluidas las exentas', value: 1 },
        { text: 'Situacion familiar distinta de las dos anteriores', value: 2 },
      ],
      editPersonalInfo: {
        name: null,
        lastName: null,
        idDocument: null,
        socialSecurity: null,
        dateOfBirth: null,
        sex: null,
        discapacityLevel: null,
        discapacityNeedsHelp: null,
        civilState: null,
        nationality: null,
        mobilePhoneNumber: null,
        phoneNumber: null,
        line: null,
        country: null,
        division: null,
        city: null,
        postalCode: null,
        personalEmail: null,
        institutionalEmail: null,
      },
      editProfessionalInfo: {
        type: null,
        faculty: null,
        department: null,
        field: null,
        position: null
      },
      editAcademicsDialog: {
        dialog: false,
        maximumStudies: null,
        accreditationAgency: null,
        dedication: null,
        titulation: null,
      },
      civilStatePicker: [
        'Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente y sometidos a patria potestad prorrogada o rehabilitada que conviven exclusivamente con VD., sin convivir también con el otro progenitor',
        'Casado/a y no separado legalmente cuyo cónyuge no obtiene rentas superiores a 1.500€ anuales, excluidas las exentas',
        'Situacion familiar distinta de las dos anteriores'
      ],
      titulationTypePicker: [
        { text: 'Bachiller o equivalente', value: 0 },
        { text: 'Ciclo Formativo de Grado Medio o equivalente', value: 1 },
        { text: 'Ciclo Formativo de Grado Superior o equivalente', value: 2 },
        { text: 'Grado / Licenciatura / Diplomatura', value: 3 },
        { text: 'Máster', value: 4 },
        { text: 'Doctorado', value: 5 },
        { text: 'Otros', value: 6 }
      ],
      dedicationSelect: [
        'Exclusiva',
        'Asociado',
      ],
      titulationSelect: [
        'Colaborador licenciado',
        'Contratado doctor',
        'Doctor acreditado',
      ],
      dedicationPicker: [
        { text: 'Exclusiva', value: 0 },
        { text: 'Asociado', value: 1 },
      ],
      titulationPicker: [
        { text: 'Colaborador Licenciado', value: 0 },
        { text: 'Contratado Doctor', value: 1 },
        { text: 'Doctor Acreditado', value: 2 },
      ],
      maximumStudiesPicker: [
        { text: 'Sin estudios finalizados', value: 0 },
        { text: 'Estudios básicos, E.G.B, E. Primaria, E. Secundaria, Bachillerato', value: 1 },
        { text: 'Formación Profesional Grado Medio', value: 2 },
        { text: 'Formación Profesional Grado Superior', value: 3 },
        { text: 'Grado, Diplomatura, Licenciatura', value: 4 },
        { text: 'Máster', value: 5 },
        { text: 'Doctorado', value: 6 },
      ],
      maximumStudiesSelect: [
        'Sin estudios finalizados',
        'Estudios básicos, E.G.B, E. Primaria, E. Secundaria, Bachillerato',
        'Formación Profesional Grado Medio',
        'Formación Profesional Grado Superior',
        'Grado, Diplomatura, Licenciatura',
        'Máster',
        'Doctorado',
      ],
    };
  },
  methods: {
    openEditAcademicsDialog() {
      this.editAcademicsDialog.maximumStudies = this.person.academics.maximumStudies;
      this.editAcademicsDialog.accreditationAgency = this.person.academics.accreditationAgency;
      this.editAcademicsDialog.dedication = this.person.academics.dedication;
      this.editAcademicsDialog.titulation = this.person.academics.titulation;
    },
    async addAcademic() {
      try {
        const formData = new FormData()
        const object = {
          person: this.id,
          name: this.addTitulation.name,
          type: this.addTitulation.type,
          date: this.addTitulation.date
        }

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        this.documents.forEach(document => formData.append(document.name, document))

        const { data } = await this.$http.post('/academics', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.person.academics.degrees.push(data);

        this.addTitulationDialog = false
        this.documents = []
        this.addTitulation.name = null
        this.addTitulation.type = null
        this.addTitulation.date = null
      } catch {
        this.addTitulationDialog = false
      }
    },
    openEditPersonalInfoDialog() {
      const { academics, professional, createdAt, updatedAt, headers, id, ...person } = this.person
      this.editPersonalInfo = { ...person }
    },
    openEditProfessionalInfoDialog() {
      const { professional } = this.person
      this.editProfessionalInfo = { ...professional }
    },
    async updateProfessionalInfo() {
      try {
        const { data } = await this.$http.patch(
          `/persons/${this.id}/professional`,
          Object.fromEntries(Object.entries(this.editProfessionalInfo).filter(([_, v]) => v != null)),
        )

        this.editProfessionalInfoDialog = false
      } catch {
        this.editProfessionalInfoDialog = false
      }
    },
    async updatePersonalInfo() {
      try {
        const formData = new FormData()
        Object.keys(this.editPersonalInfo).forEach(
          key => {
            if (this.editPersonalInfo[key] !== null) formData.append(key, this.editPersonalInfo[key])
          }
        )

        if (this.editProfilePicture !== null) formData.append('profile', this.editProfilePicture)

        for (let pair of formData.entries()) {
          console.log(pair[0]+ ', ' + pair[1]);
        }

        const { data } = await this.$http.patch(`/persons/${this.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.person = data;
        this.editPersonalInfoDialog = false;
      } catch {
        this.editPersonalInfoDialog = false;
      }
    },
    async editAcademics() {
      try {
        const { data } = await this.$http.patch(`/persons/${this.id}/academics`, {
          maximumStudies: this.editAcademicsDialog.maximumStudies,
          accreditationAgency: this.editAcademicsDialog.accreditationAgency,
          dedication: this.editAcademicsDialog.dedication,
          titulation: this.editAcademicsDialog.titulation,
        });

        this.editAcademicsDialog.dialog = false;
        this.person.academics.maximumStudies = data.academics.maximumStudies;
        this.person.academics.accreditationAgency = data.academics.accreditationAgency;
        this.person.academics.titulation = data.academics.titulation;
        this.person.academics.dedication = data.academics.dedication;
      } catch {}
    },
    openRemoveTitulationDialog(id) {
      this.removeTitulationDialog = true;
      this.removeTitulationId = id;
    },
    async removeTitulation() {
      try {
        await this.$http.delete(`titulations/${this.removeTitulationId}`);

        this.removeTitulationDialog = false;
        this.person.academics.degrees = this.person.academics.degrees.filter((degree) => degree.id !== this.removeTitulationId);
        this.removeTitulationId = null;
      } catch {
        this.removeTitulationDialog = false;
        this.removeTitulationId = null;
      }
    },
    async generateModel145() {
      try {
        const { data } = await
        this.$http.get(`/persons/${this.id}/documents/generate/mod145`, {
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Modelo 145.pdf');

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } catch {

      }
    },
    handleSetActiveAddress(payload) {
      this.person.activeAddress = payload.id;
    },
    handleSetActiveEmergencyContact(payload) {
      this.person.activeEmergencyContact = payload.id
    },
    async exportPersonToXlsx() {
      try {
        const { data } = await this.$http.get(`/persons/${this.id}/export`, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Persona-${this.person.name}-${this.person.lastName}-RRHH-${Date.parse(new Date().toString()).toString()}.xlsx`);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } catch {

      }
    },
    async generatePermitsCasualtiesReport() {
      try {
        const { data } = await this.$http.get(`/persons/${this.id}/absences/report`, {
          reponseType: 'blob'
        })
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Ausencias-${this.person.name}-${this.person.lastName}-${Date.now()}.csv`)

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
      } catch {}
    },
    async generateIncidentsReport() {
      try {
        const { data } = await this.$http.get(`/persons/${this.id}/incidents/report`, {
          responseType: 'blob'
        })

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Incidencias-${this.person.name}-${this.person.lastName}-${Date.now()}.xlsx`)

        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
      } catch {}
    },
  },
};
</script>

<style>
.titulation-card:not(:last-child) {
  margin-bottom: 2rem;
}

.header-row {
  display: flex;
}

.header-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px
}
</style>
