var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.incidents.length > 0)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.incidents},scopedSlots:_vm._u([{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.severityPicker[item.severity].text)+" ")]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openEditIncidentDialog(item)}}},[_vm._v("Editar")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.removeIncident(item.id)}}},[_vm._v("Eliminar")])]}}],null,false,1015869860)}),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.editIncidentDialog),callback:function ($$v) {_vm.editIncidentDialog=$$v},expression:"editIncidentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Editar incidente")]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"outlined":"","label":"Descripcion"},model:{value:(_vm.editIncidentInfo.description),callback:function ($$v) {_vm.$set(_vm.editIncidentInfo, "description", $$v)},expression:"editIncidentInfo.description"}}),_c('v-select',{attrs:{"outlined":"","label":"Gravedad","items":_vm.severityPicker},model:{value:(_vm.editIncidentInfo.severity),callback:function ($$v) {_vm.$set(_vm.editIncidentInfo, "severity", $$v)},expression:"editIncidentInfo.severity"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.editIncident}},[_vm._v("Editar")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.editIncidentDialog = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1):_c('div',[_c('h2',[_vm._v("Esta persona no tiene incidentes.")])]),_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("Agregar incidente")])]}}]),model:{value:(_vm.addIncidentDialog),callback:function ($$v) {_vm.addIncidentDialog=$$v},expression:"addIncidentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Agregar incidente")]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"outlined":"","label":"Descripcion"},model:{value:(_vm.addIncidentInfo.description),callback:function ($$v) {_vm.$set(_vm.addIncidentInfo, "description", $$v)},expression:"addIncidentInfo.description"}}),_c('v-select',{attrs:{"outlined":"","label":"Gravedad","items":_vm.severityPicker},model:{value:(_vm.addIncidentInfo.severity),callback:function ($$v) {_vm.$set(_vm.addIncidentInfo, "severity", $$v)},expression:"addIncidentInfo.severity"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####-##-##'),expression:"'####-##-##'"}],attrs:{"outlined":"","label":"Fecha inicio","placeholder":"YYYY-MM-DD"},model:{value:(_vm.addIncidentInfo.startDate),callback:function ($$v) {_vm.$set(_vm.addIncidentInfo, "startDate", $$v)},expression:"addIncidentInfo.startDate"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####-##-##'),expression:"'####-##-##'"}],attrs:{"outlined":"","label":"Fecha fin","placeholder":"YYYY-MM-DD"},model:{value:(_vm.addIncidentInfo.endDate),callback:function ($$v) {_vm.$set(_vm.addIncidentInfo, "endDate", $$v)},expression:"addIncidentInfo.endDate"}})],1),_c('v-file-input',{attrs:{"outlined":"","chips":"","multiple":"","label":"Documentos a adjuntar","show-size":""},model:{value:(_vm.documents),callback:function ($$v) {_vm.documents=$$v},expression:"documents"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.addIncident}},[_vm._v("Agregar")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addIncidentDialog = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }