const initialState = () => ({
  contractType: null,
  entity: null,
  location: null,
  modality: null,
  startDate: null,
  endDate: null,
  department: null,
  renumeration: null,
  subjects: null,
  undefinedEndDate: false,
  societyName: null,
  societyCif: null,
  societyRegisteredOffice: null,
  societyIncorporationDate: null,
  societyConstitutionNotary: null,
  societyProtocolNumber: null,
  societyCommercialRegistry: null,
  printDate: null,
  hours: null,
})

const commercialContract = {
  namespaced: true,
  state: initialState,
  mutations: {
    setContractType: (state, payload) => state.contractType = payload,
    setContractEntity: (state, payload) => state.entity = payload,
    setContractLocation: (state, payload) => state.location = payload,
    setContractModality: (state, payload) => state.modality = payload,
    setContractStartDate: (state, payload) => state.startDate = payload,
    setContractEndDate: (state, payload) => state.endDate = payload,
    setContractDepartment: (state, payload) => state.department = payload,
    setContractRenumeration: (state, payload) => state.renumeration = payload,
    setContractSubjects: (state, payload) => state.subjects = payload,
    setContractUndefinedEndDate: (state, payload) => state.undefinedEndDate = payload,
    setContractSocietyName: (state, payload) => state.societyName = payload,
    setContractCIF: (state, payload) => state.societyCif = payload,
    setContractRegisteredOffice: (state, payload) => state.societyRegisteredOffice = payload,
    setContractSocietyFoundationDate: (state, payload) => state.societyIncorporationDate = payload,
    setContractNotaryConstitution: (state, payload) => state.societyConstitutionNotary = payload,
    setContractProtocol: (state, payload) => state.societyProtocolNumber = payload,
    setContractInscription: (state, payload) => state.societyCommercialRegistry = payload,
    setContractPrintDate: (state, payload) => state.printDate = payload,
    setContractHours: (state, payload) => state.hours = payload,
    reset: (state) => Object.assign(state, initialState()),
    setAll: (state, payload) => Object.assign(state, payload)
  }
}

export default commercialContract
