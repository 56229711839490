const initialState = () => ({
  level: null,
  titulation: null,
  startDate: null,
  endDate: null,
  internshipAmount: null,
  feeExemption: null,
  residencyInternship: false
})

const phdInternshipContract = {
  namespaced: true,
  state: initialState,
  mutations: {
    setLevel: (state, payload) => state.level = payload,
    setTitulation: (state, payload) => state.titulaton = payload,
    setStartDate: (state, payload) => state.contractStartDate = payload,
    setEndDate: (state, payload) => state.contractEndDate = payload,
    setInternshipAmount: (state, payload) => state.internshipAmount = payload,
    setFeeExemption: (state, payload) => state.feeExemption = payload,
    setResidencyInternship: (state, payload) => state.residencyInternship = payload,
    reset: (state) => Object.assign(state, initialState()),
    setAll: (state, payload) => Object.assign(state, payload)
  }
}

export default phdInternshipContract
