<template>
  <v-dialog v-model="activate" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" @click="openContract">Editar</v-btn>
    </template>
    <v-card>
      <v-card-title>Editar contrato</v-card-title>
      <v-card-text>
        <laboral-contract :edit="true" v-if="contract.contractType === 0" />
        <adhonorem-contract :edit="true" v-if="contract.contractType === 1" />
        <commercial-contract :edit="true" v-if="contract.contractType === 2" />
        <collaboration-internship-contract :edit="true" v-if="contract.contractType === 3" />
        <phd-internship-contract :edit="true" v-if="contract.contractType === 4" />
        <faculty-stay-contract :edit="true" v-if="contract.contractType === 5" />
        <research-stay-contract :edit="true" v-if="contract.contractType === 6" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="editContract()">Editar</v-btn>
        <v-btn text @click="closeDialog()">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import LaboralContract from './LaboralContract.vue'
import AdHonoremContract from './AdHonoremContract.vue'
import CommercialContract from './CommercialContract.vue'
import CollaborationInternshipContract from './CollaborationIntershipContract.vue'
import PhDInternshipContract from './PhDInternshipContract.vue'
import ResearchStayContract from './ResearchStayContract.vue'
import StayContract from './StayContract.vue'

export default {
  props: ['contract'],
  data() {
    return {
      activate: false
    }
  },
  computed: {
    ...mapState({
      laboral: 'laboralContract',
      commercial: 'commercialContract',
      adHonorem: 'adHonoremContract',
      collaborationInternship: 'collaborationInternshipContract',
      phdInternship: 'phdInternshipContract',
      researchStay: 'researchStayContract',
      facultyStay: 'facultyStayContract'
    })
  },
  methods: {
    openContract() {
      const contractTypes = [
        'laboral', 'adHonorem', 'commercial', 'collaborationInternship',
        'phdInternship', 'researchStay', 'facultyStay'
      ]
      const { contractType, contract } = this.contract

      this.$store.commit(`${contractTypes[contractType]}Contract/setAll`, contract)
    },
    async editContract() {
      const contractTypes = [
        'laboral', 'adHonorem', 'commercial', 'collaborationInternship',
        'phdInternship', 'researchStay', 'facultyStay'
      ]

      try {
        const { data } = await this.$http.patch(
          `/contracts/${this.contract.id}`,
          Object.fromEntries(Object.entries(this[contractTypes[this.contract.contractType]]).filter(([_, v]) => v != null))
        )

        this.activate = false

        this.$store.commit(`${contractTypes[this.contract.contractType]}Contract/reset`)
        this.$emit('update-contract-list', data)
      } catch {
        this.activate = false

        this.$store.commit(`${contractTypes[this.contract.contractType]}Contract/reset`)
      }
    },
    closeDialog() {
      this.activate = false

      const contractTypes = [
        'laboral', 'adHonorem', 'commercial', 'collaborationInternship',
        'phdInternship', 'researchStay', 'facultyStay'
      ]

      this.$store.commit(`${contractTypes[this.contract.contractType]}Contract/reset`)
    }
  },
  components: {
    'laboral-contract': LaboralContract,
    'adhonorem-contract': AdHonoremContract,
    'commercial-contract': CommercialContract,
    'collaboration-internship-contract': CollaborationInternshipContract,
    'phd-internship-contract': PhDInternshipContract,
    'research-stay-contract': ResearchStayContract,
    'faculty-stay-contract': StayContract
  }
}
</script>

<style>

</style>
