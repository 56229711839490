<template>
  <v-container>
    <div v-if="contracts.length > 0">
      <v-data-table :headers="headers" :items="contracts" show-expand>
        <template v-slot:item.contractType="{ item }">
          {{ contractTypes[item.contractType] }}
        </template>
        <template v-slot:item.contract.endDate="{ item }">
          {{
            item.contract.undefinedEndDate
              ? 'Indefinido'
              : new Date(item.contract.endDate).toLocaleDateString()
          }}
        </template>
        <template v-slot:item.contract.startDate="{ item }">
          {{
            new Date(Date.parse(item.contract.startDate)).toLocaleDateString()
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-if="item.contractType === 2">
              <v-btn
                text
                v-if="item.contract.modality === 'Empresa'"
                @click="generateCommercialContractCompany(item.id)"
              >
                Generar contrato comercial empresa</v-btn>
              <v-btn
                text
                v-if="item.contract.modality === 'Cuenta ajena'"
                @click="generateCommercialContractRetired(item.id)"
              >
                Generar contrato laboral jubilado</v-btn>
              <v-btn
                text
                v-if="item.contract.modality === 'Autonomo'"
                @click="generateCommercialContract(item.id)"
              >
                Generar contrato laboral autonomo
              </v-btn>
            </div>
            <div v-if="item.contractType === 0">
              <v-btn text @click="generateAdHonoremContract(item.id)">Generar contrato Ad Honorem</v-btn>
            </div>
            <v-btn text @click="generatePRL(item.id)">Generar PRL</v-btn>
            <v-btn
              text
              @click="generateEmployeeSheet(item.id)"
            >
              Generar ficha de datos personales
            </v-btn>
            <documents-dialog :resource="item.id"/>
          </td>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h2>Esta persona todavia no tiene contratos</h2>
    </div>
    <br />
    <v-dialog v-model="activeAddressWarningDialog" persistent max-width="300">
      <v-card>
        <v-card-title>Error al generar documento</v-card-title>
        <v-card-text>
          Para poder generar un contrato, la persona tiene que tener una direccion marcada como
          activa/principal.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="activeAddressWarningDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="activeEmployeeSheetWarning" persistent max-width="500">
      <v-card>
        <v-card-title>Error al generar ficha de datos personales</v-card-title>
        <v-card-text>
          Para poder generar la ficha de datos personales, la persona tiene que tener una direccion marcada como
          activa/principal, ademas de un contacto de emergencia activo.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="activeEmployeeSheetWarning = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import CommercialContract from '../components/CommercialContract.vue'
import LaboralContract from '../components/LaboralContract.vue'
import AdHonoremContract from '../components/AdHonoremContract.vue'
import CollaborationIntershipContract from '../components/CollaborationIntershipContract.vue'
import PhdInternshipContract from '../components/PhDInternshipContract.vue'
import StayContract from '../components/StayContract.vue'
import ResearchStayContract from '../components/ResearchStayContract.vue'

import AttachedDocuments from '../components/AttachedDocuments.vue'
import AttachedDocumentsDialog from '../components/AttachedDocumentsDialog.vue'
import EditContract from '../components/EditContract.vue'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    activeAddress: {
      type: String,
      default: null,
    },
    emergencyContact: {
      type: String,
      default: null
    }
  },
  components: {
    'commercial-contract': CommercialContract,
    'laboral-contract': LaboralContract,
    'ad-honorem-contract': AdHonoremContract,
    'collaboration-intership-contract': CollaborationIntershipContract,
    'phd-internship-contract': PhdInternshipContract,
    'stay-contract': StayContract,
    'research-stay-contract': ResearchStayContract,
    'attached-documents': AttachedDocuments,
    'documents-dialog': AttachedDocumentsDialog,
    'edit-contract': EditContract
  },
  async beforeMount() {
    await this.fetchContracts();
  },
  data: () => ({
    createContractDialog: false,
    activeAddressWarningDialog: false,
    activeEmployeeSheetWarning: false,
    headers: [
      { text: 'Tipo de contrato', value: 'contractType' },
      { text: 'Entidad', value: 'contract.entity' },
      { text: 'Ubicación', value: 'contract.location' },
      { text: 'Inicio contrato', value: 'contract.startDate' },
      { text: 'Fin contrato', value: 'contract.endDate' },
      { text: '', value: 'controls', sortable: false }
    ],
    contracts: [],
    documents: [],
    contractType: null,
    contractTypes: [
      'Laboral',
      'Nombramiento Ad Honorem',
      'Comercial',
      'Beca de colaboración',
      'Beca de doctorado',
      'Estancias profesorado',
      'Estancias investigación'
    ],
  }),
  methods: {
    updateContractList(updatedContract) {
      this.contracts = this.contracts.filter((contract) => contract.id !== updatedContract.id)
      this.contracts = [ ...this.contracts, updatedContract]
    },
    async createCommercialContract() {
      try {
        const formData = new FormData()
        const object = {
         contractType: 2,
          person: this.id,
          contract: {
            entity: this.commercialContract.contractEntity,
            locatin: this.commercialContract.contractLocation,
            modality: this.commercialContract.contractModality,
            startDate: this.commercialContract.contractStartDate,
            endDate: this.commercialContract.contractEndDate,
            department: this.commercialContract.contractDepartment,
            renumeration: this.commercialContract.contractRenumeration,
            subjects: this.commercialContract.contractSubjects,
            undefinedEndDate: this.commercialContract.contractUndefinedEndDate,
            societyName: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractSocietyName
              : undefined,
            societyCif: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractCIF
              : undefined,
            societyRegisteredOffice: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractRegisteredOffice
              : undefined,
            societyIncorporationDate: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractSocietyFoundationDate
              : undefined,
            societyConstitutionNotary: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractNotaryConstitution
              : undefined,
            societyProtocolNumber: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractProtocol
              : undefined,
            societyCommercialRegistry: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractInscription
              : undefined,
            printDate: this.commercialContract.contractModality === 'Empresa'
              ? this.commercialContract.contractPrintDate
              : undefined,
            hours: this.commercialContract.contractHours
          }
        }

        Object.keys(object).forEach(key => {
          console.log(key)
          if (key === 'contract') formData.append(key, JSON.stringify(object[key]))
          else formData.append(key, object[key])
        });

        this.documents.forEach(document => formData.append(document.name, document))

        await this.$http.post('/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.closeDialog();
        this.$store.commit('commercialContract/reset')
        this.documents = []
        await this.fetchContracts();
      } catch(e) {
        console.log(e)
        this.closeDialog();
      }
    },
    async createLaboralContract() {
      try {
        const formData = new FormData()
        const object = {
          contractType: 0,
          person: this.id,
          contract: {
            ...this.laboralContract,
          }
        }

        Object.keys(object).forEach(key => {
          console.log(key)
          if (key === 'contract') formData.append(key, JSON.stringify(object[key]))
          else formData.append(key, object[key])
        });
        this.documents.forEach(document => formData.append(document.name, document))

        await this.$http.post('/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.closeDialog();
        this.$store.commit('laboralContract/reset')
        this.documents = []
        await this.fetchContracts();
      } catch {
        this.closeDialog();
      }
    },
    async createAdHonoremContract() {
      try {
        const { contractTitulations, ...rest } = this.adHonoremContract
        const formData = new FormData()
        const object = {
          contractType: 1,
          person: this.id,
          contract: rest
        }

        Object.keys(object).forEach(key => {
          if (key === 'contract') formData.append(key, JSON.stringify(object[key]))
          else formData.append(key, object[key])
        });

        this.documents.forEach(document => formData.append(document.name, document))

        const { data: { id } } = await this.$http.post('/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        const titulations = []

        for (const titulation of contractTitulations) {
          const formData = new FormData()
          const { documents, ...rest } = titulation
          const object = {
            person: this.id,
            contract: id,
            ...rest
          }

          Object.keys(object).forEach(key => {
            formData.append(key, object[key])
          })

          documents.forEach(document => formData.append(document.name, document))

          titulations.push(this.$http.post('/academics', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }))
        }

        await Promise.all(titulations)

        this.closeDialog();
        this.$store.commit('adHonoremContract/reset')
        this.documents = []
        await this.fetchContracts();
      } catch (e) {
        console.log(e)
        this.closeDialog();
      }
    },
    async createCollaborationInternshipContract() {
      try {
        const formData = new FormData()
        const object = {
          contractType: 3,
          person: this.id,
          contract: this.collaborationInternshipContract
        }

        Object.keys(object).forEach(key => {
          if (key === 'contract') formData.append(key, JSON.stringify(object[key]))
          else formData.append(key, object[key])
        });

        this.documents.forEach(document => formData.append(document.name, document))

        await this.$http.post('/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.closeDialog();
        this.$store.commit('collaborationInternshipContract/reset')
        this.documents = []
        await this.fetchContracts();
      } catch {
        this.closeDialog();
      }
    },
    async createPhdInternshipContract() {
      try {
        const formData = new FormData()
        const object = {
          contractType: 4,
          person: this.id,
          contract: this.phdInternshipContract
        }

        Object.keys(object).forEach(key => {
          if (key === 'contract') formData.append(key, JSON.stringify(object[key]))
          else formData.append(key, object[key])
        });

        this.documents.forEach(document => formData.append(document.name, document))

        await this.$http.post('/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.closeDialog();
        this.$store.commit('phdInternshipContract/reset')
        this.documents = []
        await this.fetchContracts();
      } catch {
        this.closeDialog();
      }
    },
    async createStayContract(type) {
      try {
        const formData = new FormData()
        const object = {
          contractType: type === 'Estancias profesorado'
            ? 5
            : 6,
          person: this.id,
          contract: type === 'Estancias profesorado'
            ? this.facultyStayContract
            : this.researchStayContract
        }

        Object.keys(object).forEach(key => {
          if (key === 'contract') formData.append(key, JSON.stringify(object[key]))
          else formData.append(key, object[key])
        });

        this.documents.forEach(document => formData.append(document.name, document))

        await this.$http.post('/contracts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.closeDialog();
        type === 'Estancias profesorado'
          ? this.$store.commit('facultyStayContract/reset')
          : this.$store.commit('researchStayContract/reset')
        this.documents = []
        await this.fetchContracts();
      } catch {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.createContractDialog = false;
      this.contractType = null;
    },
    async fetchContracts() {
      const { data: { data } } = await this.$http.get(`/persons/${this.id}/contracts`);

      this.contracts = data;
    },
    async removeContract(id) {
      try {
        await this.$http.delete(`/contracts/${id}`);

        this.contracts = this.contracts.filter((contract) => contract.id !== id);
      } catch {}
    },
    async generateCommercialContract(contractId) {
      if (this.activeAddress !== null) {
        try {
          const { data } = await
          this.$http.get(`/persons/${this.id}/contracts/${contractId}/generate/commercial`, {
            responseType: 'blob',
          });

          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Contrato comercial autonomo.pdf');

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        } catch {

        }
      } else {
        this.activeAddressWarningDialog = true;
      }
    },
    async generateCommercialContractRetired(contractId) {
      if (this.activeAddress !== null) {
        try {
          const { data } = await
          this.$http.get(`/persons/${this.id}/contracts/${contractId}/generate/commercialRetired`, {
            responseType: 'blob',
          });

          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Contrato comercial jubilado.pdf');

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        } catch {

        }
      } else {
        this.activeAddressWarningDialog = true;
      }
    },
    async generateCommercialContractCompany(contractId) {
      if (this.activeAddress !== null) {
        try {
          const { data } = await
          this.$http.get(`/persons/${this.id}/contracts/${contractId}/generate/commercialCompany`, {
            responseType: 'blob',
          });

          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Contrato comercial empresa.pdf');

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        } catch {

        }
      } else {
        this.activeAddressWarningDialog = true;
      }
    },
    async generateAdHonoremContract(contractId) {
      if (this.activeAddress !== null) {
        try {
          const { data } = await
          this.$http.get(`/persons/${this.id}/contracts/${contractId}/generate/adhonorem`, {
            responseType: 'blob',
          });

          await this.downloadFile('Contrato Ad Honorem.pdf', data)
        } catch {

        }
      } else {
        this.activeAddressWarningDialog = true;
      }
    },
    async downloadFile(filename, data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
    async createContract() {
      const contractTypes = {
        Comercial: async () => await this.createCommercialContract(),
        Laboral: async () => await this.createLaboralContract(),
        'Nombramiento Ad Honorem': async () => await this.createAdHonoremContract(),
        'Beca de colaboración': async () => await this.createCollaborationInternshipContract(),
        'Beca de doctorado': async () => await this.createPhdInternshipContract(),
        'Estancias profesorado': async () => await this.createStayContract('Estancias profesorado'),
        'Estancias investigación': async () => await this.createStayContract('Estancias investigación')
      }

      contractTypes[this.contractType]()
    },
    async generatePRL(contractId) {
      try {
        const { data } = await this.$http.get(`/contracts/${contractId}/documents/prl`, {
          responseType: 'blob'
        })

        await this.downloadFile('PRL.zip', data)
      } catch {}
    },
    async generateEmployeeSheet(contractId) {
      if (this.activeAddress !== null && this.emergencyContact !== null) {
        try {
          const { data } = await this.$http.get(`/contracts/${contractId}/documents/personal`, {
            responseType: 'blob'
          })

          await this.downloadFile('FICHA DE DATOS PERSONALES DEL EMPLEADO UNEATLANTICO.docx', data)
        } catch {}
      } else {
        this.activeEmployeeSheetWarning = true;
      }
    }
  },
  computed: {
    ...mapState([
      'commercialContract', 'laboralContract', 'adHonoremContract',
      'collaborationInternshipContract', 'phdInternshipContract', 'researchStayContract',
      'facultyStayContract'
    ])
  }
};
</script>
