const initialState = () => ({
  governingCouncilDate: null,
  validityDate: null,
  agreementPrintDate: null,
  appointmentAcceptanceDate: null,
  titulations: []
})

const adHonoremContract = {
  namespaced: true,
  state: initialState(),
  mutations: {
    setGoverningCouncilDate: (state, payload) => state.governingCouncilDate = payload,
    settValidityDate: (state, payload) => state.validityDate = payload,
    setAgreementPrintDate: (state, payload) => state.agreementPrintDate = payload,
    setractType: (state, payload) => state.contractType = payload,
    setAppointmentAcceptanceDate: (state, payload) => state.appointmentAcceptanceDate = payload,
    setTitulations: (state, payload) => state.titulations = payload,
    reset: (state) => Object.assign(state, initialState()),
    setAll: (state, payload) => Object.assign(state, payload)
  }
}

export default adHonoremContract
