<template>
  <v-container>
    <div v-if="emergencyContacts.length > 0" class="mt-5">
      <v-data-table :headers="headers" :items="emergencyContacts">
        <template v-slot:item.relationship="{ item }">
          {{ relationshipPicker[item.relationship].text }}
        </template>
        <template v-slot:item.controls="{ item }">
          <v-btn text @click="setActiveEmergencyContact(item.id)">Marcar como activo</v-btn>
          <v-btn text @click="openEditEmergencyContactDialog(item)">Editar</v-btn>
          <v-btn text color="red" @click="removeEmergencyContact(item.id)">Eliminar</v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="editEmergencyContactDialog" persistent>
        <v-card>
          <v-card-title>Editar contacto de emergencia</v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field outlined label="Nombre" v-model="editEmergencyContactInfo.name" />
              <v-text-field
                outlined
                label="Direccion del contacto"
                v-model="editEmergencyContactInfo.address"
              />
              <v-combobox
                multiple
                outlined
                append-icon
                v-model="editEmergencyContactInfo.phoneNumbers"
                label="Telefonos de contacto"
                chips
                deletable-chips
              />
              <v-select
                v-model="editEmergencyContactInfo.relationship"
                :items="relationshipPicker"
                label="Tipo de relacion"
                outlined
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="editEmergencyContact">Editar</v-btn>
            <v-btn text @click="editEmergencyContactDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h2>Esta persona no tiene contactos de emergencia</h2>
    </div>
    <v-dialog
      v-model="addEmergencyContactDialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Agregar contacto de emergencia</v-btn>
      </template>
      <v-card>
        <v-card-title>Agregar contacto de emergencia</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field outlined label="Nombre" v-model="addEmergencyContactInfo.name" />
            <v-text-field
              outlined
              label="Direccion del contacto"
              v-model="addEmergencyContactInfo.address"
            />
            <v-combobox
              multiple
              outlined
              append-icon
              v-model="addEmergencyContactInfo.phoneNumbers"
              label="Telefonos de contacto"
              chips
              deletable-chips
            />
            <v-select
              v-model="addEmergencyContactInfo.relationship"
              :items="relationshipPicker"
              label="Tipo de relacion"
              outlined
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="addEmergencyContact">Agregar</v-btn>
          <v-btn text @click="addEmergencyContactDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ['id'],
  async beforeMount() {
    const { data } = await this.$http.get(`/persons/${this.id}/emergency-contacts`);

    this.emergencyContacts = data;
  },
  data() {
    return {
      headers: [
        { text: 'Nombre contacto', value: 'name' },
        { text: 'Dirección de contacto', value: 'address' },
        { text: 'Numero de contacto', value: 'phoneNumbers' },
        { text: 'Tipo de relación', value: 'relationship' },
        { text: '', value: 'controls', sortable: false }
      ],
      emergencyContacts: [],
      addEmergencyContactDialog: false,
      editEmergencyContactDialog: false,
      addEmergencyContactInfo: {
        name: null,
        address: null,
        phoneNumbers: [],
        relationship: 1,
      },
      editEmergencyContactInfo: {
        id: null,
        name: null,
        address: null,
        phoneNumbers: [],
        relationship: null,
      },
      relationshipPicker: [
        { text: 'Conyuge', value: 0 },
        { text: 'Pariente', value: 1 },
        { text: 'Otro', value: 2 },
      ],
    };
  },
  methods: {
    openEditEmergencyContactDialog(item) {
      this.editEmergencyContactDialog = true;
      this.editEmergencyContactInfo.id = item.id;
      this.editEmergencyContactInfo.name = item.name;
      this.editEmergencyContactInfo.address = item.address;
      this.editEmergencyContactInfo.phoneNumbers = item.phoneNumbers;
      this.editEmergencyContactInfo.relationship = item.relationship;
    },
    async editEmergencyContact() {
      try {
        const { id, ...rest } = this.editEmergencyContactInfo;
        const { data } = await this.$http.patch(`/emergency-contacts/${id}`, {
          ...rest,
        });

        this.editEmergencyContactDialog = false;
        this.editEmergencyContactInfo.id = null;
        this.editEmergencyContactInfo.name = null;
        this.editEmergencyContactInfo.address = null;
        this.editEmergencyContactInfo.phoneNumbers = null;
        this.editEmergencyContactInfo.relationship = null;

        this.emergencyContacts.splice(this.emergencyContacts.findIndex((x) => x.id === id), 1, data);
      } catch {
        this.editEmergencyContactDialog = false;
      }
    },
    async addEmergencyContact() {
      try {
        const { data } = await this.$http.post('/emergency-contacts', {
          person: this.id,
          ...this.addEmergencyContactInfo,
        });

        this.emergencyContacts.push(data);

        this.addEmergencyContactDialog = false;
        this.addEmergencyContactInfo.name = null;
        this.addEmergencyContactInfo.address = null;
        this.addEmergencyContactInfo.phoneNumbers = [];
        this.addEmergencyContactInfo.relationship = 1;
      } catch {
        this.addEmergencyContactDialog = false;
      }
    },
    async removeEmergencyContact(id) {
      try {
        await this.$http.delete(`/emergency-contacts/${id}`);

        this.emergencyContacts = this.emergencyContacts.filter((ec) => ec.id !== id);
      } catch {}
    },
    async setActiveEmergencyContact(id) {
      try {
        const { data } = await this.$http.get(`/emergency-contacts/${id}/actions/principal`)
        this.emergencyContacts.splice(this.emergencyContacts.findIndex((contact) => contract.id === id))

        this.$emit('set-active-contact', data)
      } catch {}
    }
  },
};
</script>

<style>
.row {
  margin: 0 !important;
}

.contact-card:not(:last-child) {
  margin-bottom: 2rem;
}
</style>
